import { Avatar, Divider, Pagination, Stack, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Form, Modal, Button, Image, Row, Col, Alert, Accordion, Offcanvas, InputGroup, Spinner } from 'react-bootstrap'
import { Media, Table } from 'reactstrap'
import { RiImageAddFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../context/ContextProvider';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient, { pagination_size } from '../../../axios-client';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";

const styles = `
.custom-modal-size {
  max-width: 600px; 
}
`
function AllUsers() {
  const { user } = useStateContext()
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPwdChange, setShowPwdChange] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState()

  const handleClose = () => {
    setShow(false)
    emptyData()
  };
  const handleShow = () => {
    setShow(true)
    emptyData()
  };

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const handleClosePwdChange = () => {
    setShowPwdChange(false)
    emptyData()
  };
  const handleShowPwdChange = () => setShowPwdChange(true);

  const handleCloseEdit = () => {
    setShowEdit(false)
    emptyData()
  };
  const handleShowEdit = () => {
    setShowEdit(true)
    emptyData()
  };

  const handleCloseCanvas = () => setShowCanvas(false);
  const toggleShowCanvas = () => setShowCanvas(true);


  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation()
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState('');
  const [messageP, setMessageP] = useState('');
  const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
  const [paginationData, setPaginationData] = useState()
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEtat, setSelectedEtat] = useState(null);
  const [id, setId] = useState(null);
  const [userEdit, setUserEdit] = useState();

  const [image, setImage] = useState()
  const [selectedImage, setSelectedImage] = useState()

  const [data, setData] = useState([])


  ////////////////////////////////the size of the table//////////////////////////////
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const PAGE_SIZE = pagination_size;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////


  ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordColor, setPasswordColor] = useState('');

  const checkPasswordStrength = () => {
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const startLength = /^.{2,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let missingRequirements = [];

    if (!specialchar.test(password)) {
      missingRequirements.push(t("caractère spécial"));
    } else if (!lowercaseRegex.test(password)) {
      missingRequirements.push(t("minuscule"));
    } else if (!uppercaseRegex.test(password)) {
      missingRequirements.push(t("majuscule"));
    } else if (!digitRegex.test(password)) {
      missingRequirements.push(t("chiffres"));
    } else if (!minLengthRegex.test(password)) {
      missingRequirements.push(t("longueur minimale de 8 caractères"));
    }

    if (missingRequirements.length > 0) {
      const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
      setPasswordStrength(missingText);
      setPasswordColor('#b04758');
    } else {
      setPasswordStrength(t('Le mot de passe est correct!'));
      setPasswordColor('green');
    }
  }
  const checkPasswordStrengthEdit = () => {
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const startLength = /^.{2,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let missingRequirements = [];

    if (!specialchar.test(newPassword)) {
      missingRequirements.push(t("caractère spécial"));
    } else if (!lowercaseRegex.test(newPassword)) {
      missingRequirements.push(t("minuscule"));
    } else if (!uppercaseRegex.test(newPassword)) {
      missingRequirements.push(t("majuscule"));
    } else if (!digitRegex.test(newPassword)) {
      missingRequirements.push(t("chiffres"));
    } else if (!minLengthRegex.test(newPassword)) {
      missingRequirements.push(t("longueur minimale de 8 caractères"));
    }

    if (missingRequirements.length > 0) {
      const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
      setPasswordStrength(missingText);
      setPasswordColor('#b04758');
    } else {
      setPasswordStrength(t('Le mot de passe est correct!'));
      setPasswordColor('green');
    }
  }

  useEffect(() => {
    checkPasswordStrength();
  }, [password]);

  useEffect(() => {
    checkPasswordStrengthEdit();
  }, [newPassword]);
  // check the password complexity
  const isPasswordValid = (pwd) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let errors = [];

    if (!minLengthRegex.test(pwd)) {
      errors.push(t('Le mot de passe doit comporter au moins 8 caractères.'));
    }

    if (!digitRegex.test(pwd)) {
      errors.push(t('Le mot de passe doit contenir au moins un chiffre.'));
    }

    if (!lowercaseRegex.test(pwd)) {
      errors.push(t('Le mot de passe doit contenir au moins une lettre minuscule.'));
    }

    if (!uppercaseRegex.test(pwd)) {
      errors.push(t('Le mot de passe doit contenir au moins une lettre majuscule.'));
    }
    if (!specialchar.test(pwd)) {
      errors.push(t('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).'));
    }
    if (pwd.length > 20) {
      errors.push(t('Le mot de passe ne doit pas dépasser 20 caractères.'));
    }

    if (errors.length > 0) {
      if (show) {
        setMessage(errors[0]);
      }
      else if (showEdit) { setMessageP(errors[0]); }

      return false;
    }

    return passwordRegex.test(pwd);
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    if (selectedImage) {
      setImage(URL.createObjectURL(selectedImage))
    }
  }, [selectedImage])
  const [loadingFilter, setLoadingFilter] = useState()
  const getData = async () => {
    try {
      setLoadingFilter(true)
      await axiosClient.get(`/clients/getAllClients/?page=${page + 1}&page_size=${pageSize}&user=${user.id}&etat=${selectedEtat}&search_term=${searchTerm}`)
        .then((response) => {
          let filteredData = response.data.results;
          setPaginationData(response.data);
          setTotalPages(Math.ceil(response.data.count / pageSize));
          setData(filteredData);
          setLoading(false)
          setLoadingFilter(false)

        })
        .catch((error) => {
          // console.error(error);
          setLoading(false)
          setLoadingFilter(false)

        });
    } catch (error) {
      // console.error('ERROR : ', error);
      setLoading(false)
      setLoadingFilter(false)


    }
  }
  useEffect(() => {
    setLoading(true)
    getData()

  }, [])

  useEffect(() => {
    getData()

  }, [user, page, pageSize, selectedEtat, searchTerm])


  function update() {
    axiosClient.get(`/clients/getAllClients/?page=${page + 1}&page_size=${pageSize}&user=${user.id}`)
      .then((response) => {
        let filteredData = response.data.results;
        setPaginationData(response.data);
        setTotalPages(Math.ceil(response.data.count / pageSize));

        setData(filteredData); setData(response.data.results);
      })
      .catch((error) => {
        // console.error(error);

      });
  }

  const isFormFilled = selectedImage && nom && prenom && tel && email && password && confirmPassword;

  const onSubmit = async ev => {
    ev.preventDefault()
    const check = await axiosClient.get(`/email_exists/${email}/`);
    if (check.data.exists) {
      setIsLoading(false);
      setMessage(t("Cet email existe déja"));
      return;
    }
    const formData = new FormData();
    formData.append('first_name', prenom);
    formData.append('last_name', nom);
    formData.append('email', email);
    formData.append('tel', tel);
    formData.append('password', password);
    formData.append('agency', user.id);
    formData.append('etat', true);

    if (selectedImage) {
      formData.append('image', selectedImage);
    }


    if (!isFormFilled) {
      setMessage(t('Veuillez remplir tous les champs'));
      return;
    }

    else if ((nom && nom.trim() === "") || (prenom && prenom.trim() === "") || (email && email.trim() === "") || (tel && tel.trim() === "") || (password && password.trim() === "") || (confirmPassword && confirmPassword.trim() === "")) {
      setMessage(t('Veuillez remplir les champs correctement'))
      setIsLoading(false)
      return
    }
    else if (!emailRegex.test(email)) {
      setMessage(t("Veuillez entrer un email valide."));
      setIsLoading(false)
      return
    }
    if (password !== confirmPassword) {
      setMessage(t("Veuillez confirmer le mot de passe"));
      setIsLoading(false)
      return
    }
    else if (passwordStrength !== 'Fort' && password.length > 0 && !isPasswordValid(password)) {
      return;
    }
    // else if (passwordStrength !== 'Fort' && password.current.value.length > 0 && !isPasswordValid(password.current.value)) {
    //     return;
    // }

    else {
      axiosClient.post('/clients/', formData)
        .then((response) => {
          toast.success(t("Le client est ajouté avec succès"), {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setIsLoading(false)


          emptyData()
          handleClose()
          update()
          setTotalPages(Math.ceil(response.data.count / pageSize));
        })
        .catch((err) => {
          const response = err.response;
          // console.error(response);

          setMessage(response.data)
          setIsLoading(false)
        })
    }

  }
  const onEdit = async (event, idEdit) => {
    event.preventDefault();

    if (email) {
      const check = await axiosClient.get(`/email_exists/${email}/`);
      if (check.data.exists) {
        setIsLoading(false);
        setMessage(t("Cet email existe déja"));
        return;
      }
    }
    const formData = new FormData();
    if (prenom) { formData.append("first_name", prenom); }
    if (nom) { formData.append("last_name", nom); }
    if (email) { formData.append("last_name", email); }
    if (tel) { formData.append("tel", tel); }
    if (selectedImage) formData.append("image", selectedImage);
    if (email && !emailRegex.test(email)) {
      setMessage(t("Veuillez entrer un email valide."));
      setIsLoading(false)
      return
    }
    if (newPassword && !isPasswordValid(newPassword)) {
      return;
    }

    try {
      const response = await axiosClient.put(`/update_user/${idEdit}/`, formData)
      let responsePwd = null;
      if (oldPassword && newPassword && confirmPassword) {
        responsePwd = await axiosClient.put(`/change_password/${idEdit}/`, {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        });
      }

      if (response.data.message && (!responsePwd || responsePwd.data.message)) {

        toast.success(t("les informations ont été modifiées avec succès"), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        emptyData()
        handleCloseEdit()
        update()
        setTotalPages(Math.ceil(response.data.count / pageSize));

      }
      if (responsePwd) { setMessageP(responsePwd.data.error); }

    } catch (err) {
      // toast.warning("Erreur de modification", {
      //   position: "top-center",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: false,
      //   pauseOnHover: false,
      //   draggable: false,
      //   progress: undefined,
      //   theme: "light",
      // });
      // console.error(err);

    }

  };

  const emptyData = ev => {
    setNom()
    setPrenom()
    setEmail()
    setSelectedImage()
    setImage()
    setTel()
    setMessage('')
    setMessageP('')
    setPasswordColor('')
    setPasswordStrength('')
    setPassword('')
    setConfirmPassword('')
    setNewPassword('')
    setOldPassword('')
  }

  const handleEtatClick = (id, etat) => {
    const formData = new FormData();
    formData.append("etat", !etat);

    axiosClient.put(`/update_client_etat/${id}/`, formData)
      .then((response) => {
        setData(rows => rows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              etat: !etat,
            };
          }
          return row;
        }));
      })
      .catch((error) => {
        // console.error(error)
      });
    if (!etat) {

      toast.success(t("le compte est activé"), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    else {

      toast.error(t("le compte est désactivé"), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  }
  const handleDelete = (id) => {
    if (!id) return;

    axiosClient.delete(`/clients/deleteUser/?id=${id}`)
      .then((response) => {
        // Remove the deleted user from the displayed data
        setData((prevData) => prevData.filter((row) => row.id !== id));

        // Show success toast notification
        toast.success(t("Le client a été supprimé avec succès"), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });

        // Close the delete confirmation modal or any other related UI element
        handleCloseDelete();
      })
      .catch((error) => {
        // console.error("Error deleting user:", error);

        // Optionally, show an error toast notification
        toast.error(t("Une erreur s'est produite lors de la suppression de client"), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    !loading ?
      <div>
        {!loadingFilter ?
          <>
            <ToastContainer />

            <style>{styles}</style>
            <div className="row d-flex justify-content-between align-items-center my-3 mb-4">
              <h3 className=' col-12 col-sm-6'>{t("Liste des clients")}</h3>
              <div className="col-12 col-sm-6 d-flex justify-content-sm-end justify-content-end mt-2 mt-sm-0">
                <Button variant="primary" size='md' style={{ fontSize: "16px" }} onClick={toggleShowCanvas} className="me-2">
                  <FaFilter />
                </Button>
                {/* <div></div> */}
                <Button style={{ fontSize: "16px" }} onClick={handleShow} size='md' variant="secondary  ">{t("Ajouter un client")}</Button>

              </div>
            </div>
            <Table style={{ borderColor: "lightgray" }} responsive>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>{t('Activité')}</th>
                  <th>{t("Nom complet")}</th>
                  <th>email</th>
                  <th>{t("Annonceur")}</th>
                  <th>{t("Téléphone")}</th>
                  <th>{t("Date de création")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody >
                {(!searchTerm && selectedEtat === null && paginationData?.length === 0) &&
                  <tr>
                    <td colSpan={9}>{t("Il n'y a pas encore de données")}</td>
                  </tr>
                }
                {(paginationData?.length !== 0) &&
                  data.map((user) => (
                    <tr key={user.id} className="align-middle">
                      <th scope="row">
                        <Avatar style={{ objectFit: "cover", borderRadius: "10%" }} width={45} height={45} src={user.image} variant='square' />
                      </th>
                      <th  >
                        <Form>
                          <Form.Check
                            checked={user.etat}
                            type="switch"
                            id="custom-switch"
                            label=""
                            onChange={() => handleEtatClick(user.id, user.etat)}

                          />

                        </Form>
                      </th>

                      <td>
                        <div style={{ display: "flex", flexDirection: 'column' }}>
                          <span>{user.first_name} </span>
                          <span> {user.last_name}</span>
                        </div>
                      </td>
                      <td>{user.email}</td>
                      <td>{user.AdvertiserId}</td>
                      <td>{user.tel}</td>
                      <td>{new Date(user.date_creation).toLocaleDateString(t('fr-FR'))}</td>
                      <td>
                        <div className='d-flex justify-content-between'>
                          <span onClick={() => {
                            handleShowEdit()
                            setUserEdit(user)
                          }} className='mdi mdi-pencil' style={{ fontSize: 23, cursor: "pointer" }}></span>

                          <span onClick={() => {

                            handleShowDelete()
                            setId(user.id)
                          }}
                            className='mdi mdi-close' style={{ fontSize: 23, cursor: "pointer" }}></span>
                        </div>
                      </td>
                    </tr>
                  ))}


                {(((searchTerm || selectedEtat !== null) && paginationData?.length === 0)) && (
                  <tr>
                    <td colSpan={9}>{t("Aucune ligne correspondante n'a été trouvée.")}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {paginationData?.length !== 0 &&
              <Row>
                <Col lg={12} className="d-flex justify-content-end">

                  <TablePagination
                    component="div"
                    count={totalPages * pageSize} // Total number of items
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handlePageSizeChange}

                    rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                    showFirstButton={true}
                    showLastButton={true}
                    sx={{
                      ".MuiTablePagination-displayedRows": {
                        margin: 0
                      },
                      ".MuiTablePagination-selectLabel": {
                        display: 'none',
                        margin: 0
                      },
                      ".MuiSelect-select": {
                        backgroundColor: "#ededed"
                      }
                    }}
                  />
                </Col>
              </Row>

            }
          </>
          :
          <>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10
            }}>
              <span>{t("Chargement")}</span>
              <Spinner animation="grow" size='sm' />
            </div>
          </>
        }


        <Modal dialogClassName="custom-modal-size" size='800px' show={show} onHide={handleClose}>

          <Modal.Header closeButton>
            <Modal.Title>{t("Ajouter un client")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form autoComplete="off">
              {message &&
                <Alert variant={"danger"}>
                  {message}
                </Alert>
              }
              <Row>
                <Col style={{ justifyItems: "center", alignContent: "center" }} lg="4">

                  <Media className='mb-3' style={{ justifyContent: 'center', alignItems: "center" }}>
                    <div className="position-relative" style={{ width: 120, height: 120 }}>
                      <Avatar
                        style={{ objectFit: "cover", borderRadius: "5%" }}
                        sx={{ width: 120, height: 120 }}
                        src={image}
                        variant="square"
                      />
                      <label htmlFor="imageInput" className="position-absolute" style={{
                        cursor: 'pointer',
                        bottom: -15,
                        right: -10,
                        border: "3px solid white",
                        borderRadius: "50%"
                      }}>
                        <input
                          id="imageInput"
                          type="file"
                          name='logo'
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={(e) => {
                            setSelectedImage(e.target.files[0])
                          }
                          }
                        />
                        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#232939', borderRadius: '50%', width: 30, height: 30 }}>
                          <RiImageAddFill size={15} color='#fff' />
                        </div>
                      </label>
                    </div>

                  </Media>
                </Col>
                <Col className='d-none d-lg-flex' style={{ display: 'flex', justifyContent: "center" }} lg="1">
                  <Divider style={{ borderColor: "gray" }} orientation="vertical" variant="middle" />
                </Col>
                <Col lg="7">
                  <Row>
                    <Form.Group className="mb-3" >
                      <Form.Label>{t("Nom")}</Form.Label>
                      <Form.Control
                        value={nom}
                        onChange={(e) => setNom(e.target.value)}
                        type="text"
                        placeholder={t("Entrez le nom")}
                        autoFocus
                        autoComplete="off"

                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3" >
                      <Form.Label> {t("Prénom")}</Form.Label>
                      <Form.Control
                        value={prenom}
                        onChange={(e) => setPrenom(e.target.value)}
                        type="text"
                        placeholder={t("Entrez le prénom")}
                        autoFocus
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Row>


                </Col>

              </Row>
              <Form.Group className="mb-3" >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder={t("nom@exemple.com")}
                  autoFocus
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>{t("Numéro de téléphone")}</Form.Label>
                <Form.Control
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                  type="text"
                  placeholder={t("Entrez le numéro de téléphone")}
                  autoFocus
                  autoComplete="off"
                />
              </Form.Group>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t("Mot de passe")}</Form.Label>
                    <Form.Control
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder={t('Entrez le mot de passe')}
                      autoFocus
                      type='password'
                      autoComplete="new-password"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t("Confirmez le mot de passe")}</Form.Label>
                    <Form.Control
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder={t('Confirmez le mot de passe')}
                      autoFocus
                      type='password'
                      autoComplete="new-password"
                    />
                  </Form.Group>
                </Col>
              </Row>
              {password && password.length > 0 ?
                <span style={{ color: passwordColor }} >{`${passwordStrength}`}</span>
                : ""}

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Annuler")}
            </Button>
            <Button isLoading={isLoading} disabled={!isFormFilled} variant="primary" onClick={onSubmit}>
              {t("Ajouter")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal dialogClassName="custom-modal-size" show={showEdit} onHide={handleCloseEdit}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Modifier le client")}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "70vh", overflow: "auto" }}>
            {message &&
              <Alert variant={"danger"}>
                {message}
              </Alert>
            }
            <Form>
              <Row>
                <Col style={{ justifyItems: "center", alignContent: "center" }} lg="4">

                  <Media className='mb-3' style={{ justifyContent: 'center', alignItems: "center" }}>
                    <div className="position-relative" style={{ width: 120, height: 120 }}>
                      <Avatar
                        style={{ objectFit: "cover", borderRadius: "5%" }}
                        sx={{ width: 120, height: 120 }}
                        src={selectedImage ? image : userEdit?.image}
                        variant="square"
                      />
                      <label htmlFor="imageInput" className="position-absolute" style={{
                        cursor: 'pointer',
                        bottom: -15,
                        right: -10,
                        border: "3px solid white",
                        borderRadius: "50%"
                      }}>
                        <input
                          id="imageInput"
                          type="file"
                          name='logo'
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={(e) => {
                            // handleFileChange(e)
                            setSelectedImage(e.target.files[0])
                          }
                          }
                        />
                        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#232939', borderRadius: '50%', width: 30, height: 30 }}>
                          <RiImageAddFill size={15} color='#fff' />
                        </div>
                      </label>
                    </div>

                  </Media>
                </Col>
                <Col className='d-none d-lg-flex' style={{ display: 'flex', justifyContent: "center" }} lg="1">
                  <Divider style={{ borderColor: "gray" }} orientation="vertical" variant="middle" />
                </Col>
                <Col lg="7">
                  <Row>
                    <Form.Group className="mb-3" >
                      <Form.Label>{t("Nom")}</Form.Label>
                      <Form.Control
                        defaultValue={userEdit?.last_name}
                        type="text"
                        placeholder={t("Entrez le nom")}
                        onChange={(e) => setNom(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3" >
                      <Form.Label> {t("Prénom")}</Form.Label>
                      <Form.Control
                        defaultValue={userEdit?.first_name}
                        type="text"
                        placeholder={t("Entrez le prénom")}
                        autoFocus
                        onChange={(e) => setPrenom(e.target.value)}

                      />
                    </Form.Group>
                  </Row>


                </Col>

              </Row>
              <Form.Group className="mb-3" >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  defaultValue={userEdit?.email}
                  type="email"
                  placeholder={t("nom@exemple.com")}
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}

                />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>{t("Numéro de téléphone")}</Form.Label>
                <Form.Control
                  defaultValue={userEdit?.tel}
                  type="text"
                  placeholder={t("Entrez le numéro de téléphone")}
                  autoFocus
                  onChange={(e) => setTel(e.target.value)}

                />
              </Form.Group>


            </Form>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("Modifier le mot de passe")}</Accordion.Header>
                <Accordion.Body>
                  {messageP &&
                    <Alert variant={"danger"}>
                      {messageP}
                    </Alert>
                  }
                  <Form>

                    <Form.Group className="mb-3" >
                      <Form.Label>{t("Ancien mot de passe")}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={t("Entrez l'ancien mot de passe")}
                        autoFocus
                        onChange={(e) => setOldPassword(e.target.value)}

                      />
                    </Form.Group>


                    <Form.Group className='mb-3'>
                      <Form.Label>{t("Nouveau mot de passe")}</Form.Label>
                      <Form.Control
                        placeholder={t('Entrez le nouveau mot de passe')}
                        autoFocus
                        type='password'
                        value={newPassword}
                        // autoComplete="new-password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        onCopy={(e) => e.preventDefault()}

                      />
                    </Form.Group>
                    {newPassword && newPassword.length > 0 ?
                      <div style={{ color: passwordColor, marginBottom: 10 }} >{`${passwordStrength}`}</div>

                      : ""}
                    <Form.Group className='mb-3'>
                      <Form.Label>{t("Confirmez le mot de passe")}</Form.Label>
                      <Form.Control
                        placeholder={t('Confirmez le mot de passe')}
                        autoFocus
                        type='password'
                        // autoComplete="new-password"
                        onChange={(e) => setConfirmPassword(e.target.value)}

                      />
                    </Form.Group>

                  </Form>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEdit}>
              {t("Annuler")}
            </Button>
            <Button variant="primary" onClick={(e) => onEdit(e, userEdit?.id)}>
              {t("Enregistrer")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Supprimer")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              {t("Annuler")}
            </Button>
            <Button variant='danger' onClick={() => handleDelete(id)}>
              {t("Supprimer")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Offcanvas style={{ width: "250px", backgroundColor: '#232939' }} scroll={true} backdrop={false} show={showCanvas} onHide={handleCloseCanvas}>
          <Offcanvas.Header style={{ color: 'white' }} closeButton>
            <Offcanvas.Title style={{ fontSize: "20px" }} >{t("Filtres")}</Offcanvas.Title>
            <style>
              {`
      .btn-close {
        filter: invert(1);
      }
    `}
            </style>
          </Offcanvas.Header>
          <Offcanvas.Body className='py-3'>

            <div className='d-flex' style={{ flexDirection: 'column', gap: 15 }}>
              <InputGroup >
                <InputGroup.Text style={{ background: "white", width: 'fit-content', borderRight: "none" }} >
                  <IoSearchSharp style={{ padding: 0, margin: 0 }} />
                </InputGroup.Text>

                <Form.Control
                  value={searchTerm}
                  onChange={(e) => {
                    setPage(0)
                    setSearchTerm(e.target.value)
                  }}
                  style={{ paddingLeft: 0, marginLeft: 0, background: "white", borderLeft: "none" }}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder={t('Recherche')}
                />
              </InputGroup>
              <span style={{ color: 'white' }}>{t("Activité")} :</span>
              <Form.Select value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')} aria-label="Default select example">
                <option value='' >{t("Sélectionnez l'activité")}</option>
                <option value="true">{t("Activé")}</option>
                <option value="false">{t("Désactivé")}</option>
              </Form.Select>
            </div>

          </Offcanvas.Body>
        </Offcanvas>
      </div>
      :
      <>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 10
        }}>
          <span>{t("Chargement")}</span>
          <Spinner animation="grow" size='sm' />
        </div>
      </>
  )
}

export default AllUsers