import React, { useEffect, useState } from 'react';
import { Client } from '@adzerk/decision-sdk';
import { Alert, Button, CloseButton, Form, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosClient, { idSiteDAB, networkId } from '../../../axios-client';
import { FaFilter } from 'react-icons/fa';
import DataCountries from '../campaign/flights/DataCountries';

const AdComponent = () => {
    const [adContent, setAdContent] = useState(null);
    const [adContentVideo, setAdContentVideo] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [types, setTypes] = useState([]);
    const [zones, setZones] = useState([]);
    const [ad, setAd] = useState();
    const [adType, setAdType] = useState(null);  // Default ad type
    const [keyword, setKeyword] = useState("");
    const [campaign, setCampaign] = useState("");
    const [multiplier, setMultiplier] = useState();
    const [zoneId, setZoneId] = useState(""); // Add state for zoneId
    const [loading, setLoading] = useState(false);
    const [adId, setAdId] = useState();

    const [dataSites, setDataSites] = useState([])
    const [siteId, setSiteId] = useState(idSiteDAB)

    const { t } = useTranslation()
    const getDataSites = async () => {
        try {
            const res = await axiosClient.get(`get-sites/`);
            return res.data.items; // Return the data directly
        } catch (error) {
            // console.error('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    };
    const fetchData = async () => {
        try {
            const data = await getDataSites();
            setDataSites(data)
        } catch (error) {
            // console.error('Error fetching data:', error);
        }
    };

    const getDataAdTypes = async () => {
        try {
            const res = await axiosClient.get(`get-adTypes/`);
            setTypes(res.data.items)
            return res.data.items.map(item => item.Id)
        } catch (error) {
            // console.error('ERROR :', error);
            throw error; // Optional: re-throw the error if you want to handle it elsewhere
        }
    }
    const getZones = async () => {
        try {
            const res = await axiosClient.get(`get-zones/`);
            setZones(res.data.filter(e => e.SiteId === idSiteDAB))
            return res.data.filter(e => e.SiteId === idSiteDAB)
        } catch (error) {
            // console.error('ERROR :', error);
            throw error;
        }
    }
    const getUserIp = async () => {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    };


    useEffect(() => {
        fetchData();
        getZones()
        getDataAdTypes()
    }, []);

    // useEffect(() => {
    //     const fetchAdDecision = async () => {
    //         setLoading(true)
    //         try {
    //             const userIp = await getUserIp();
    //             const data = await getDataAdTypes();
    //             const zones = await getZones()
    //             const sites = await getDataSites()
    //             const client = new Client({ networkId: networkId, siteId: idSiteDAB });
    //             // const client = new Client({ networkId: 23, siteId: 667480 });

    //             const request = {
    //                 placements: [{
    //                     adTypes: adType ? [parseInt(adType)] : data,
    //                     siteId: siteId ? parseInt(siteId) : sites[0].Id,
    //                     divName: 'div0',
    //                     ...(campaign && { campaignId: parseInt(campaign) }),
    //                     ...(zoneId && { zoneIds: parseInt(zoneId) }),
    //                 }],
    //                 user: { key: "abc" },
    //                 ...(keyword && { keywords: [keyword] }),
    //                 ip: userIp
    //             };

    //             console.log('request.placements : ', request, client);



    //             const response = await client.decisions.get(request);

    //             if (response.decisions && response.decisions[Object.keys(response.decisions)[0]]) {

    //                 setAdId(response.decisions.div0[0].adId)
    //                 setAd(response.decisions.div0[0]);

    //                 const ad = response.decisions.div0[0].contents[0];
    //                 const impression = response.decisions.div0[0].impressionUrl;

    //                 setAdContent(ad);
    //                 setAdContentVideo(ad.data);
    //                 // Fire the impression pixel when the ad is viewed
    //                 if (impression) {
    //                     client.pixels.fire({ url: impression });
    //                 }
    //             }
    //             setLoading(false)
    //         } catch (error) {
    //             console.error('Error fetching ad decision:', error);
    //             setLoading(false)
    //         }
    //     };

    //     fetchAdDecision();
    // }, [adType, keyword, siteId, zoneId, campaign]);

    async function update() {
        // handleCloseCanvas()

        setLoading(true)
        try {
            const userIp = await getUserIp();
            const data = await getDataAdTypes();
            const sites = await getDataSites()
            const zones = await getZones()
            const client = new Client({ networkId: networkId, siteId: idSiteDAB });

            const filteredProperties = Object.fromEntries(
                Object.entries(properties).filter(([key, value]) => value !== '')
            );

            console.log('filteredProperties 2 :', filteredProperties);

            //You can optionally set an event multiplier on impressions, clicks, conversions, and custom events via the eventMultiplier parameter. 
            //it will set the count of that event in reporting to the value of the multiplier.
            const request = {
                placements: [{
                    adTypes: adType ? [parseInt(adType)] : data,
                    siteId: siteId ? parseInt(siteId) : sites[0].Id,
                    eventMultiplier: multiplier, //this will add the multiplier value to the number of each impression
                    divName: 'div0',
                    ...(campaign && { campaignId: parseInt(campaign) }),
                    ...(zoneId && { zoneIds: parseInt(zoneId) }),
                    ...(Object.keys(filteredProperties).length > 0 && { properties: filteredProperties })
                }],
                user: { key: "abc" },
                ...(keyword && { keywords: [keyword] }),
                ip: userIp
            };

            console.log('request.placements : ', request);


            const response = await client.decisions.get(request);

            console.log("response : ", response);


            if (response.decisions && response.decisions[Object.keys(response.decisions)[0]]) {

                setAdId(response.decisions.div0[0].adId)
                setAd(response.decisions.div0[0]);

                const ad = response.decisions.div0[0].contents[0];
                const impression = response.decisions.div0[0].impressionUrl;

                setAdContent(ad);
                setAdContentVideo(ad.data);
                // Fire the impression pixel when the ad is viewed
                if (impression) {
                    client.pixels.fire({ url: impression });
                }

                setIsVisible(true);
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching ad decision:', error);
            setLoading(false)
        }
    }
    const handleAdClick = () => {
        if (ad && ad.clickUrl) {
            const client = new Client({ networkId: networkId, siteId: idSiteDAB });
            client.pixels.fire({ url: ad?.clickUrl }).then(() => {
                window.open(ad.clickUrl, '_blank');
            });
        }
    };

    const handleManualImpression = () => {
        if (ad && ad.impressionUrl) {
            const client = new Client({ networkId: networkId, siteId: idSiteDAB });
            client.pixels.fire({ url: ad?.impressionUrl }).then((r) => {
            });
        }
    };

    const handleCloseAd = () => {
        setIsVisible(false);
    };


    // Show Filter
    const [showCanvas, setShowCanvas] = useState(false);
    const handleCloseCanvas = () => setShowCanvas(false);
    const toggleShowCanvas = () => setShowCanvas(true);

    const [properties, setProperties] = useState({
        age: '',
        sexe: '',
        categorie: '',
        pays: ''
    })

    useEffect(() => {
        const filteredProperties = Object.fromEntries(
            Object.entries(properties).filter(([key, value]) => value !== '')
        );
        console.log('filteredProperties : ', filteredProperties)

    }, [properties])

    const getModifiedHTML = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");

        // Sélectionne l'image et modifie ses attributs
        const img = doc.querySelector("img");
        if (img) {
            img.setAttribute("width", "100%"); // ou la largeur que vous voulez
            img.setAttribute("height", "auto"); // ajuste la hauteur automatiquement
        }

        return doc.body.innerHTML;
    };

    return (
        <>
            <Button variant="primary" size='md' style={{ fontSize: "12px" }} onClick={toggleShowCanvas} className="me-2">
                <FaFilter /> {t("Filtre")}
            </Button>
            <div style={{ position: 'relative', padding: '10px', width: 'fit-content', margin: '10px auto', textAlign: 'center' }}>



                {!loading && !isVisible &&
                    <div>
                        <Alert variant={"primary"}>
                            {t("Utilisez le filtre pour voir les annonces")} !
                        </Alert>
                    </div>}


                {!loading ?
                    (isVisible && adContent && (
                        <>
                            <div>
                                Ad id : {adId}
                            </div>
                            <div style={{ position: 'relative', border: '1px solid #ccc', padding: '10px', textAlign: 'center', maxHeight: 900, maxWidth: 900 }}>

                                <div style={{ display: 'flex', alignItems: "end", justifyContent: "end" }}>
                                    <CloseButton onClick={handleCloseAd} />

                                </div>
                                {adContent.body &&
                                <div dangerouslySetInnerHTML={{ __html: getModifiedHTML(adContent.body) }} onClick={handleAdClick} />

                                }
                                {adContentVideo.ctPlayback_URL &&
                                    <iframe
                                        onClick={handleAdClick}
                                        src={adContentVideo.ctPlayback_URL}
                                        title='video'
                                        preload='auto'
                                        // controls
                                        autoPlay
                                        width= "100%"
                                        
                                        height= "auto"
                                        style={{minWidth:"600px", minHeight:"350px"}}
                                    ></iframe>}


                                <div style={{ marginTop: '10px' }}>
                                    <Button onClick={handleAdClick} style={{ marginRight: '10px' }}>
                                        {t("Clic")}
                                    </Button>
                                    <Button style={{ marginRight: '10px' }} onClick={() => handleManualImpression()}>
                                        Impression
                                    </Button>

                                </div>
                            </div>
                        </>
                    )) :
                    "loading.."}
            </div>


            <Offcanvas style={{ width: "350px", backgroundColor: '#232939' }} scroll={true} show={showCanvas} onHide={handleCloseCanvas}>
                <Offcanvas.Header style={{ color: 'white' }} closeButton>
                    <Offcanvas.Title style={{ fontSize: "20px" }} >{t("Filtres")}</Offcanvas.Title>
                    <style>
                        {`
      .btn-close {
        filter: invert(1);
      }
    `}
                    </style>
                </Offcanvas.Header>
                <Offcanvas.Body className='py-3'>

                    <div className='d-flex' style={{ flexDirection: 'column', gap: 15 }}>
                    <Form.Group>
                            <Form.Label>{t("Multiplicateur")}</Form.Label>
                            <Form.Control
                                type="number"
                                value={multiplier}
                                onChange={(e) => setMultiplier(parseInt(e.target.value))}
                                placeholder={t("Entrez un nombre entre 1 et 100000000")}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t("Campagne")}</Form.Label>
                            <Form.Control
                                type="number"
                                value={campaign}
                                onChange={(e) => setCampaign(parseInt(e.target.value))}
                                placeholder={t("Entrez l'id de campagne")}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>{t("Type d'annonce")} {t("(facultatif)")} </Form.Label>

                            <Form.Select
                                value={adType}
                                onChange={(e) => setAdType(e.target.value)}
                            >
                                <option value="">{t("Choisissez le type d'annonce")}</option>
                                {types.map((val, key) => {
                                    return (
                                        <option key={key} value={val.Id} title={val.Name}>{val.Name}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>


                        {parseInt(siteId) !== parseInt(idSiteDAB) &&
                            <Form.Group>
                                <Form.Label>{t("Mot-clé")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    placeholder={t("Entrez un mot-clé")}
                                />
                            </Form.Group>
                        }

                        <Form.Group>
                            <Form.Label>{t("Support")} {t("(facultatif)")}</Form.Label>
                            {/* <Form.Select
                    value={zoneId}
                    onChange={(e) => setZoneId(e.target.value)}
                >
                    <option value="">{t("Choisissez une zone")}</option>
                    <option value="322038">DAB+</option>
                    <option value="322039">Radio Karaoke</option>
                </Form.Select> */}
                            <Form.Select
                                value={siteId}
                                onChange={(e) => {
                                    setSiteId(e.target.value)
                                    if (parseInt(e.target.value) !== parseInt(idSiteDAB)) {
                                        setZoneId("")
                                        setKeyword('')
                                        setProperties({
                                            age: '',
                                            sexe: '',
                                            categorie: '',
                                            pays: ''
                                        })
                                    }
                                }}
                            >
                                <option value="">{t("Choisissez un support")}</option>
                                {dataSites.map((val, key) => {
                                    return (
                                        <option key={key} value={val.Id} title={val.Title}>
                                            {val.Title}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                        {parseInt(siteId) === parseInt(idSiteDAB) &&
                            <Form.Group>
                                <Form.Label>{t("Radio")} {t("(facultatif)")}</Form.Label>
                                {/* <Form.Select
                    value={zoneId}
                    onChange={(e) => setZoneId(e.target.value)}
                >
                    <option value="">{t("Choisissez une zone")}</option>
                    <option value="322038">DAB+</option>
                    <option value="322039">Radio Karaoke</option>
                </Form.Select> */}
                                <Form.Select
                                    value={zoneId}

                                    onChange={(e) => setZoneId(e.target.value)}
                                >
                                    <option value="">{t("Choisissez une radio")}</option>
                                    {zones
                                        .map((zone, key) => (
                                            <option key={key} value={zone.Id} title={zone.Name}>
                                                {zone.Name}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                        }
                        {parseInt(siteId) !== parseInt(idSiteDAB) && (
                            <>
                                <div className='d-flex gap-2'>
                                    <Form.Group className='col-6'>
                                        <Form.Label>{t("Âge")}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name='age'
                                            min={1}
                                            value={properties.age}
                                            onChange={(e) => setProperties({
                                                ...properties,
                                                age: parseInt(e.target.value) || '' // Handle empty input
                                            })}
                                            onBlur={(e) => {
                                                if (parseInt(e.target.value) < 1) { // Corrected this line
                                                    setProperties({
                                                        ...properties,
                                                        age: '' // Reset age to empty
                                                    });
                                                }
                                            }}
                                            placeholder={t("Entrez l'âge")}
                                        />

                                    </Form.Group>

                                    <Form.Group className='col-6'>
                                        <Form.Label>{t("Sexe")}</Form.Label>
                                        <Form.Select
                                            name="sexe"
                                            value={properties.sexe}
                                            onChange={(e) => setProperties({
                                                ...properties,
                                                sexe: e.target.value
                                            })}
                                        >
                                            <option value=''>-- {t("Choisir")} --</option>
                                            <option value={'homme'}>{t('homme')}</option>
                                            <option value={'femme'}>{t('femme')}</option>
                                            <option value={'non-binaire'}>{t('non-binaire')}</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>

                                <div className='d-flex gap-2'>
                                    <Form.Group className='col-6'>
                                        <Form.Label>{t("Catégorie")}</Form.Label>
                                        <Form.Select
                                            name="categorie"
                                            value={properties.categorie}
                                            onChange={(e) => setProperties({
                                                ...properties,
                                                categorie: e.target.value
                                            })}
                                        >
                                            <option value=''>-- {t("Choisir")} --</option>
                                            <option value={'groupe1'}>{t('Agriculteurs exploitants / Agricultrices exploitantes')}</option>
                                            <option value={'groupe2'}>{t("Artisans / Artisanes, commerçants / commerçantes et chefs / cheffes d'entreprise")}</option>
                                            <option value={'groupe3'}>{t("Cadres et professions intellectuelles supérieures")}</option>
                                            <option value={'groupe4'}>{t("Professions intermédiaires")}</option>
                                            <option value={'groupe5'}>{t("Employés / Employées")}</option>
                                            <option value={'groupe6'}>{t("Ouvriers / Ouvrières")}</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className='col-6'>
                                        <Form.Label>{t("Pays")}</Form.Label>
                                        <Form.Select
                                            name="pays"
                                            value={properties.pays}
                                            onChange={(e) => setProperties({
                                                ...properties,
                                                pays: e.target.value
                                            })}
                                        >
                                            <option value=''>-- {t('Choisir')} --</option>
                                            {DataCountries.map((val, key) => (
                                                <option key={key} value={val.value} title={val.title}>{t(`${val.title}`)}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </>
                        )}

                        <Button className='' onClick={() => update()}>
                            {t("Chercher")}
                        </Button>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default AdComponent;
