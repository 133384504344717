import { success } from "toastr";

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const fetchUsersRequest = () => ({
    type: FETCH_USERS_REQUEST
});

export const fetchUsersSuccess = (users) => ({
    type: FETCH_USERS_SUCCESS,
    payload: users
});

export const fetchUsersFailure = (error) => ({
    type: FETCH_USERS_FAILURE,
    payload: error
});

export const addUserRequest = (userData) => ({
    type: ADD_USER_REQUEST,
    payload: userData
});

export const addUserSuccess = () => ({
    type: ADD_USER_SUCCESS
});

export const addUserFailure = (error) => ({
    type: ADD_USER_FAILURE,
    payload: error
});