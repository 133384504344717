import React, { useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
  TabContent, TabPane, Nav,
  NavItem, NavLink, Row, Col
} from 'reactstrap';
import classnames from 'classnames';
import Standard from './Standard';
import Synchronous from './Synchronous';
import Email from './Email';
import PartyAdServer from './PartyAdServer';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function GenerateAdCodeCampaign() {

  const { id } = useParams()
  const location = useLocation()
  const { t } = useTranslation()


  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // Toggle active state for Tab
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  return (
    <div style={{
      display: 'block', width: '100%', padding: 30
    }}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active:
                currentActiveTab === '1'
            })}
            onClick={() => { toggle('1'); }}
          >
            Standard
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({
              active:
                currentActiveTab === '2'
            })}
            onClick={() => { toggle('2'); }}
          >
            Synchronous
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active:
                currentActiveTab === '3'
            })}
            onClick={() => { toggle('3'); }}
          >
            Email
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active:
                currentActiveTab === '4'
            })}
            onClick={() => { toggle('4'); }}
          >
            3rd Party Ad Server
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={currentActiveTab}>
        <TabPane tabId="1">
          <Row style={{ marginTop: 10 }}>
            <Standard type ={location.state.type} setType={location.state.setType} name={location.state.name} sizeAd={location.state.sizeAd} />
          </Row>
        </TabPane>
        {/* <TabPane tabId="2">
          <Row style={{ marginTop: 10 }}>
            <Synchronous />
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row style={{ marginTop: 10 }}>
            <Email />
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row style={{ marginTop: 10 }}>
            <PartyAdServer />
          </Row>
        </TabPane> */}
      </TabContent>
    </div >
  );
}

export default GenerateAdCodeCampaign;