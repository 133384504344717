import React, { useEffect, useRef, useState } from 'react'
import './Style.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Accordion, ButtonGroup, Col, InputGroup, Offcanvas, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from 'reactstrap';
import axiosClient, { link, pagination_size } from '../../../axios-client';
import axios from 'axios';
import { TablePagination, TextField } from '@mui/material';
import { useStateContext } from '../../../context/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FaFilter } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import CheckoutForm from '../payment/CheckoutForm';

// import Adzerk from '@adzerk/management-sdk';
// const apiKey = '0483FF10a0C13a4E89a9766a56ACE2B34D0D'
// const endpoint = 'https://cors-anywhere.herokuapp.com/https://api.kevel.co/v1/campaign/?advertiserId=3264604';

const Adzerk = require('@adzerk/decision-sdk');
const apiKey = '0483FF10a0C13a4E89a9766a56ACE2B34D0D'


function Campaign() {

    const { user } = useStateContext()
    const { t } = useTranslation()

    const navigate = useNavigate()

    const [addCampaignModal, setAddCampaignModal] = useState(false);
    const [editCampaignModal, setEditCampaignModal] = useState(false);
    const [deleteCampaignModal, setDeleteCampaignModal] = useState(false);

    const [showCanvas, setShowCanvas] = useState(false);
    const handleCloseCanvas = () => setShowCanvas(false);
    const toggleShowCanvas = () => setShowCanvas(true);

    //
    const [nameFilter, setNameFilter] = useState('')
    const [idFilter, setIDFilter] = useState('')
    const [statusFilter, setStatusFilter] = useState('')
    //


    const [anchorEl, setAnchorEl] = useState(null);
    const [dataToGenerateCode, setDataToGenerateCode] = useState({})
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseAddCampaignModal = () => {
        setName('')
        setCheckDailyCapAmount(false)
        setDailyCapAmount()
        setCheckLifetimeCapAmount(false)
        setLifetimeCapAmount()
        setCheckEnableFrequencyCapping(false)
        setFreqCap()
        setFreqCapDuration()
        setFreqCapType()
        setDontAffectParentFreqCap(false)
        setErrors({
            Name: '',
            DailyCapAmount: '',
            LifetimeCapAmount: '',
            FreqCap: '',
            FreqCapDuration: '',
        })
        setAddCampaignModal(false)
    };
    const handleShowAddCampaignModal = () => {
        setAddCampaignModal(true)
    }

    const handleCloseEditCampaignModal = () => {
        setSelectedCampaignId()
        setName('')
        setCheckDailyCapAmount(false)
        setDailyCapAmount()
        setCheckLifetimeCapAmount(false)
        setLifetimeCapAmount()
        setCheckEnableFrequencyCapping(false)
        setFreqCap()
        setFreqCapDuration()
        setFreqCapType()
        setDontAffectParentFreqCap(false)
        setErrors({
            Name: '',
            DailyCapAmount: '',
            LifetimeCapAmount: '',
            FreqCap: '',
            FreqCapDuration: '',
        })
        setEditCampaignModal(false)
    };
    const handleShowEditCampaignModal = () => setEditCampaignModal(true);

    const handleCloseDeleteCampaignModal = () => {
        setSelectedCampaignId()
        setDeleteCampaignModal(false)
    };
    const handleShowDeleteCampaignModal = () => setDeleteCampaignModal(true);

    // Variables 
    const [dataCampaign, setDataCampaign] = useState([])


    const [name, setName] = useState('')
    const [checkDailyCapAmount, setCheckDailyCapAmount] = useState(false)
    const [dailyCapAmount, setDailyCapAmount] = useState()
    const [checkLifetimeCapAmount, setCheckLifetimeCapAmount] = useState(false)
    const [lifetimeCapAmount, setLifetimeCapAmount] = useState()
    const [checkEnableFrequencyCapping, setCheckEnableFrequencyCapping] = useState(false)
    const [freqCap, setFreqCap] = useState()
    const [freqCapDuration, setFreqCapDuration] = useState()
    const [freqCapType, setFreqCapType] = useState()
    const [dontAffectParentFreqCap, setDontAffectParentFreqCap] = useState(false)

    const [selectedCampaignId, setSelectedCampaignId] = useState()

    const [loading, setLoading] = useState(true)
    const [loadingFilter, setLoadingFilter] = useState()

    ////////////////////////////////the size of the table//////////////////////////////
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const PAGE_SIZE = pagination_size;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // Get Campaign
    const fetchCampaign = async (advertiserId, userId) => {
        if (user) {
            setLoadingFilter(true)
            try {
                const response = await axiosClient.get(`get-campaigns/?page=${page + 1}&page_size=${pageSize}&advertiserId=${advertiserId}&user=${userId}&nameFilter=${nameFilter}&idFilter=${idFilter}&etat=${statusFilter}`)
                setDataCampaign(response?.data)
                // setTotalPages(Math.ceil(response.data.count / pageSize));
                setTotalPages(response?.data[0]?.totalPages);
                setLoadingFilter(false)
                setLoading(false)

            } catch (error) {
                // console.error('Error Fetching : ', error);
                setLoadingFilter(false)
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (user.advertiser) {
            setLoading(true)
            fetchCampaign(user?.advertiser, user?.id)
        }
    }, [user])

    // For Filter
    useEffect(() => {
        if (user.advertiser) {
            fetchCampaign(user?.advertiser, user?.id)
        }

    }, [page, pageSize, nameFilter, idFilter, statusFilter])




    // Add new Campaign
    const [loadingAdd, setLoadingAdd] = useState()
    const handleSubmit = async e => {
        e.preventDefault()


        let newErrors = { ...errors };
        // Validate form fields
        let firstErrorRef = null;

        if (name) {
            newErrors.Name = ''
        } else {
            newErrors.Name = t("Titre est obligatoire")
            if (!firstErrorRef) firstErrorRef = nameRef;
        }

        if (checkDailyCapAmount) {
            if (dailyCapAmount) {
                newErrors.DailyCapAmount = ''
            } else {
                newErrors.DailyCapAmount = 'DailyCapAmount is required'
                if (!firstErrorRef) firstErrorRef = dailyCapAmountRef;
            }
        }
        else {
            newErrors.DailyCapAmount = ''
        }
        if (checkLifetimeCapAmount) {
            if (lifetimeCapAmount) {
                newErrors.LifetimeCapAmount = ''
            } else {
                newErrors.LifetimeCapAmount = 'Life time Cap Amount is required'
                if (!firstErrorRef) firstErrorRef = lifetimeCapAmountRef;
            }
        }
        else {
            newErrors.LifetimeCapAmount = ''
        }
        if (checkEnableFrequencyCapping) {
            if (freqCap) {
                newErrors.FreqCap = ''
            } else {
                newErrors.FreqCap = 'Freq Cap is required'
                if (!firstErrorRef) firstErrorRef = freqCapRef;
            }
            if (freqCapDuration) {
                if (parseInt(freqCapType) === 1) {
                    if (parseInt(freqCapDuration) < 0 || parseInt(freqCapDuration) > 168) {
                        newErrors.FreqCapDuration = 'The number must be between 0 and 168 hours.';
                        if (!firstErrorRef) firstErrorRef = freqCapDurationRef;
                    } else {
                        newErrors.FreqCapDuration = '';
                    }
                } else if (parseInt(freqCapType) === 2) {
                    if (parseInt(freqCapDuration) < 0 || parseInt(freqCapDuration) > 180) {
                        newErrors.FreqCapDuration = 'The number must be between 0 and 180 days.';
                        if (!firstErrorRef) firstErrorRef = freqCapDurationRef;
                    } else {
                        newErrors.FreqCapDuration = '';
                    }
                } else if (parseInt(freqCapType) === 3) {
                    if (parseInt(freqCapDuration) < 0 || parseInt(freqCapDuration) > 4400) {
                        newErrors.FreqCapDuration = 'The number must be between 0 and 4400 minutes.';
                        if (!firstErrorRef) firstErrorRef = freqCapDurationRef;
                    } else {
                        newErrors.FreqCapDuration = '';
                    }
                }
            } else {
                newErrors.FreqCapDuration = 'Freq Cap Duration is required'
                if (!firstErrorRef) firstErrorRef = freqCapDurationRef;
            }
            if (freqCapType) {
                newErrors.FreqCapType = ''
            } else {
                newErrors.FreqCapType = 'This field is required'
                if (!firstErrorRef) firstErrorRef = freqCapTypeRef;
            }
        }
        else {
            newErrors.FreqCap = ''
            newErrors.FreqCapDuration = ''
        }
        setErrors(newErrors);
        // Scroll to the first error if any
        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        if (!firstErrorRef) {
            try {
                setLoadingAdd(true)
                const response = await axiosClient.post(`create-campaign/`, {
                    name: name,
                    dailyCapAmount: dailyCapAmount,
                    lifetimeCapAmount: lifetimeCapAmount,
                    freqCap: freqCap,
                    freqCapDuration: freqCapDuration,
                    freqCapType: freqCapType,
                    dontAffectParentFreqCap: dontAffectParentFreqCap,
                    isActive: true,
                    advertiserId: user?.advertiser,
                    user: user?.id
                })
                toast.success(t("La campagne est ajoutée avec succès"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                setDataCampaign([response.data, ...dataCampaign])
                setLoadingAdd(false)
                handleCloseAddCampaignModal()
                return response.data; // Return the advertiser object
            } catch (error) {
                setLoadingAdd(false)
                // console.error('Error creating campaign:', error.response ? error.response.data : error.message);
                throw error; // Rethrow to handle it in the calling function
            }
        }
    }

    const updateIsActive = async (id, etat) => {
        await axiosClient.put(`update-isActive-campaign/${id}/`).then(res => {
            setDataCampaign(dataCampaign.map(item => {
                if (item.Id === res.data.Id) {
                    return {
                        ...item,
                        IsActive: res.data.IsActive
                    }
                }
                return item
            }))
            if (res.data.IsActive) {
                toast.success(t("La campagne est activée"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                toast.success(t("La campagne est désactivée"), {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }


        })
    }

    const deleteCampaign = async (id) => {
        setLoadingAdd(true)
        await axiosClient.put(`delete-campaign/${id}/?user=${user?.id}`).then(() => {
            setDataCampaign(dataCampaign.filter(e => e.Id !== id))
            setLoadingAdd(false)
            handleCloseDeleteCampaignModal()

            toast.success(t("La campagne est supprimée avec succès"), {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }).catch(e => {
            setLoadingAdd(false)
            // console.error('ERROR : ', e);

        })
    }

    const [dataCampaignId, setDataCampaignId] = useState()
    const [errors, setErrors] = useState({
        Name: '',
        DailyCapAmount: '',
        LifetimeCapAmount: '',
        FreqCap: '',
        FreqCapDuration: '',
        FreqCapType: '',
    })

    // Refs for form controls
    const nameRef = useRef(null);
    const dailyCapAmountRef = useRef(null);
    const lifetimeCapAmountRef = useRef(null);
    const freqCapRef = useRef(null);
    const freqCapDurationRef = useRef(null);
    const freqCapTypeRef = useRef(null);

    const getCampaingWithId = async (id) => {
        await axiosClient.get(`get-campaign-with-id/${id}/`).then(res => {
            setDataCampaignId(res.data)
            if (res.data.Name) {
                setName(res.data.Name)
            }
            if (res.data.DailyCapAmount) {
                setCheckDailyCapAmount(true)
                setDailyCapAmount(res.data.DailyCapAmount)
            } else {
                setCheckDailyCapAmount(false)
                setDailyCapAmount()
            }
            if (res.data.LifetimeCapAmount) {
                setCheckLifetimeCapAmount(true)
                setLifetimeCapAmount(res.data.LifetimeCapAmount)
            } else {
                setCheckLifetimeCapAmount(false)
                setLifetimeCapAmount()
            }
            if (res.data.FreqCap) {
                setCheckEnableFrequencyCapping(true)
                setFreqCap(res.data.FreqCap)
                setFreqCapDuration(res.data.FreqCapDuration)
                setFreqCapType(res.data.FreqCapType)
            } else {
                setCheckEnableFrequencyCapping(false)
                setFreqCap()
                setFreqCapDuration()
                setFreqCapType(1)
            }
            res.data.DontAffectParentFreqCap ? setDontAffectParentFreqCap(true) : setDontAffectParentFreqCap(false)
            handleShowEditCampaignModal()
            return res.data
        })
    }

    const updateCampaign = async (id) => {
        let newErrors = { ...errors };
        // Validate form fields
        let firstErrorRef = null;
        const formData = new FormData()

        if (name) {
            formData.append('Name', name)
            newErrors.Name = ''
        } else {
            newErrors.Name = t("Titre est obligatoire")
            if (!firstErrorRef) firstErrorRef = nameRef;
        }
        if (checkDailyCapAmount) {
            if (dailyCapAmount) {
                formData.append('DailyCapAmount', dailyCapAmount)
                newErrors.DailyCapAmount = ''
            } else {
                newErrors.DailyCapAmount = 'DailyCapAmount is required'
                if (!firstErrorRef) firstErrorRef = dailyCapAmountRef;
            }
        }
        else {
            formData.append('DailyCapAmount', 0)
            newErrors.DailyCapAmount = ''
        }
        if (checkLifetimeCapAmount) {
            if (lifetimeCapAmount) {
                formData.append('LifetimeCapAmount', lifetimeCapAmount)
                newErrors.LifetimeCapAmount = ''
            } else {
                newErrors.LifetimeCapAmount = 'Life time Cap Amount is required'
                if (!firstErrorRef) firstErrorRef = lifetimeCapAmountRef;
            }
        }
        else {
            formData.append('LifetimeCapAmount', 0)
            newErrors.LifetimeCapAmount = ''
        }
        if (checkEnableFrequencyCapping) {
            if (freqCap) {
                formData.append('FreqCap', freqCap)
                newErrors.FreqCap = ''
            } else {
                newErrors.FreqCap = 'Freq Cap is required'
                if (!firstErrorRef) firstErrorRef = freqCapRef;
            }
            if (freqCapDuration) {
                formData.append('FreqCapDuration', freqCapDuration)
                newErrors.FreqCapDuration = ''
            } else {
                newErrors.FreqCapDuration = 'Freq Cap Duration is required'
                if (!firstErrorRef) firstErrorRef = freqCapDurationRef;
            }
            if (freqCapType) {
                formData.append('FreqCapType', freqCapType)
            }
        }
        else {
            formData.append('FreqCap', 0)
            formData.append('FreqCapDuration', 0)
            formData.append('FreqCapType', 0)
            newErrors.FreqCap = ''
            newErrors.FreqCapDuration = ''
        }
        formData.append('dontAffectParentFreqCap', dontAffectParentFreqCap)
        setErrors(newErrors);
        // Scroll to the first error if any
        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        if (!firstErrorRef) {
            try {
                setLoadingAdd(true)
                const response = await axiosClient.put(`update-campaign/${id}/`, formData);
                if (response.status === 200) {
                    toast.success(t("La campagne est modifiée avec succès"), {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                    setDataCampaign(dataCampaign.map(item => {
                        if (item.Id === response.data.Id) {
                            return {
                                ...item,
                                Name: response.data.Name,
                                DailyCapAmount: response.data.DailyCapAmount,
                                LifetimeCapAmount: response.data.LifetimeCapAmount,
                                FreqCap: response.data.FreqCap,
                                FreqCapDuration: response.data.FreqCapDuration,
                                FreqCapType: response.data.FreqCapType,
                                dontAffectParentFreqCap: response.data.dontAffectParentFreqCap,
                            }
                        }
                        return item
                    }))
                    setLoadingAdd(false)
                    handleCloseEditCampaignModal()
                }
            } catch (error) {
                setLoadingAdd(false)
                // console.error('ERROR: ', error);

                // toast.error("Failed to update campaign", {
                //     position: "top-center",
                //     autoClose: 3000,
                //     hideProgressBar: false,
                //     closeOnClick: false,
                //     pauseOnHover: false,
                //     draggable: false,
                //     progress: undefined,
                //     theme: "light",
                // });
                return
            }
        }
    };


    return (
        !loading ?
            <div>
                <ToastContainer />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: (dataCampaign.length !== 0) ? 'end' : 'center', gap: 10 }}>
                    {/* {<Button style={{ padding: '5px 10px', borderRadius: 7, background: 'lightgray', border: 'none', boxShadow: '5px 5px 10px lightgray' }} onClick={toggleShowCanvas}>
                        <FaFilter />
                    </Button>} */}
                    <Button
                        // style={{ padding: '5px 10px', borderRadius: 7, background: '#f18bf1', border: 'none', boxShadow: '5px 5px 10px lightgray' }} 
                        variant='primary'
                        onClick={handleShowAddCampaignModal}>
                        {t("Ajouter une campagne")}
                    </Button>
                </div>

                {!loadingFilter ? <>
                    {
                        dataCampaign.length !== 0 ?
                            <Table className='mt-4' style={{ width: '100%', borderColor: 'lightgray' }} responsive>
                                <thead>
                                    <tr>
                                        <th scope="col">{t("Actif")}</th>
                                        <th scope="col">{t("Titre")}</th>
                                        <th scope="col">ID</th>
                                        <th scope="col">{t("Début")}</th>
                                        <th scope="col">{t("Fin")}</th>
                                        {/* <th scope="col">Status</th> */}
                                        <th scope="col">Impressions</th>
                                        <th scope="col">{t("Clics")}</th>
                                        <th scope="col">{t("Clics uniques")}</th>
                                        <th scope="col">Conversions</th>
                                        <th scope="col">{t("Revenue")}</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataCampaign.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td className='d-flex justify-content-center align-self-center'>
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" onChange={() => updateIsActive(val.Id, val.IsActive)} checked={val.IsActive} type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ cursor: 'pointer' }} />
                                                    </div>
                                                </td>
                                                <td>

                                                    <Link to={`/flights/${val.Id}`} className=" waves-effect" onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                                        <span>{val.Name}</span>
                                                    </Link>
                                                    {/* <a className='d-flex flex-column' onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                                        <span>Second campaign</span>
                                    </a> */}
                                                </td>
                                                <td>{val.Id}</td>
                                                <td>
                                                    {val.StartDate ? new Date(val.StartDate).toLocaleString('fr-FR', {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                    }) : null}
                                                </td>
                                                <td>
                                                    {val.EndDate ? new Date(val.EndDate).toLocaleString('fr-FR', {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                    }) : null}
                                                </td>
                                                {/* <td>
                                                <div style={{ height: 15, width: 15, borderRadius: '50%', background: 'red' }} />
                                            </td> */}
                                                <td>{val.impressions}</td>
                                                <td>{val.clicks}</td>
                                                <td>{val.uniqueclicks ? val.uniqueclicks : '-'}</td>
                                                <td>{val.conversions}</td>
                                                <td>${val.revenue}</td>
                                                <td>
                                                    <div className='d-flex justify-content-between'>
                                                        <span className="mdi mdi-pencil" style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
                                                            setSelectedCampaignId(val.Id)
                                                            getCampaingWithId(val.Id)
                                                        }}></span>
                                                        <span className="mdi mdi-close" style={{ fontSize: 25, cursor: 'pointer' }} onClick={() => {
                                                            handleShowDeleteCampaignModal()
                                                            setSelectedCampaignId(val.Id)
                                                        }}></span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            :
                            <span>{t("Aucun élément trouvé.")}</span>
                    }

                    {/* PAGINATION */}
                    {
                        dataCampaign.length !== 0 &&
                        <Row>
                            <Col lg={12} className="d-flex justify-content-end">

                                <TablePagination
                                    component="div"
                                    count={totalPages * pageSize} // Total number of items
                                    page={page}
                                    onPageChange={handlePageChange}
                                    rowsPerPage={pageSize}
                                    onRowsPerPageChange={handlePageSizeChange}

                                    rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                                    showFirstButton={true}
                                    showLastButton={true}
                                    sx={{
                                        ".MuiTablePagination-displayedRows": {
                                            margin: 0
                                        },
                                        ".MuiTablePagination-selectLabel": {
                                            display: 'none',
                                            margin: 0
                                        },
                                        ".MuiSelect-select": {
                                            backgroundColor: "#ededed"
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    }
                </>
                    : <>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 10
                        }}>
                            <span>{t("Chargement")}</span>
                            <Spinner animation="grow" size='sm' />
                        </div>
                    </>
                }

                {/* PAGINATION */}

                {/* Modal to Add new Campaign */}

                <Modal show={addCampaignModal} onHide={handleCloseAddCampaignModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Nouvelle campagne")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        style={{
                            maxHeight: '60vh',
                            overflow: 'auto'
                        }}>
                        <Form onSubmit={handleSubmit}>
                            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Pick an Advertiser</Form.Label>
                            <Form.Control type="text" placeholder="Pick an Advertiser" />
                        </Form.Group> */}

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>{t("Titre campagne")}</Form.Label>
                                <Form.Control ref={nameRef} type="text" value={name} placeholder={t("Titre campagne")} onChange={e => setName(e.target.value)} />
                                {errors.Name && <Form.Text className="text-danger">{errors.Name}</Form.Text>}
                            </Form.Group>



                            {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Salesperson</Form.Label>
                            <Form.Control type="text" placeholder="Salesperson" />
                        </Form.Group> */}

                            {/* <Form.Group className="mb-3 d-flex align-items-center" controlId="dailyRevenue">
                                <Form.Check className='w-50' type="checkbox" label="Daily revenue cap" value={checkDailyCapAmount} onChange={() => setCheckDailyCapAmount(!checkDailyCapAmount)} />
                                {checkDailyCapAmount && <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Form.Control ref={dailyCapAmountRef} className='' type="number" min={0} placeholder="" value={dailyCapAmount} onChange={e => setDailyCapAmount(e.target.value)} />
                                    {errors.DailyCapAmount && <Form.Text className="text-danger">{errors.DailyCapAmount}</Form.Text>}
                                </div>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3 d-flex align-items-center" controlId="lifetimeRevenue">
                                <Form.Check className='w-50' type="checkbox" label="Lifetime revenue cap" value={checkLifetimeCapAmount} onChange={() => setCheckLifetimeCapAmount(!checkLifetimeCapAmount)} />
                                {checkLifetimeCapAmount &&
                                    <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Form.Control ref={lifetimeCapAmountRef} className='' type="number" min={0} placeholder="" value={lifetimeCapAmount} onChange={e => setLifetimeCapAmount(e.target.value)} />
                                        {errors.LifetimeCapAmount && <Form.Text className="text-danger">{errors.LifetimeCapAmount}</Form.Text>}
                                    </div>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="enableFrequency">
                                <Form.Check type="checkbox" label="Enable frequency capping" value={checkEnableFrequencyCapping} onChange={e => setCheckEnableFrequencyCapping(!checkEnableFrequencyCapping)} />
                                {checkEnableFrequencyCapping && <div className='px-3'>
                                    <Form.Label className='m-0' style={{ fontWeight: 100 }}>Display ads from this campaign</Form.Label>
                                    <Form.Control ref={freqCapRef} type="number" placeholder="" value={freqCap} onChange={e => setFreqCap(e.target.value)} />
                                    {errors.FreqCap && <> <Form.Text className="text-danger">{errors.FreqCap}</Form.Text><br /></>}
                                    <Form.Label className='m-0' style={{ fontWeight: 100 }}>times per</Form.Label>
                                    <Form.Control ref={freqCapDurationRef} type="number" placeholder="" value={freqCapDuration} onChange={e => setFreqCapDuration(e.target.value)} />
                                    {errors.FreqCapDuration && <Form.Text className="text-danger">{errors.FreqCapDuration}</Form.Text>}
                                    <Form.Select ref={freqCapTypeRef} type="text" placeholder="" value={freqCapType} onChange={e => setFreqCapType(e.target.value)} >
                                        <option value="">-- Choose --</option>
                                        <option value={1}>Hour(s)</option>
                                        <option value={2}>Day(s)</option>
                                        <option value={3}>Minute(s)</option>
                                    </Form.Select>
                                    {errors.FreqCapType && <Form.Text className="text-danger">{errors.FreqCapType}</Form.Text>}
                                </div>}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="lastCheck">
                                <Form.Check type="checkbox" label="This campaign should not affect the advertiser frequency cap" value={dontAffectParentFreqCap} onChange={() => setDontAffectParentFreqCap(!dontAffectParentFreqCap)} />
                            </Form.Group> */}

                            <div className="alert alert-secondary" style={{ borderRadius: 0 }} role="alert">
                                {t("Lorsque les vols se trouvent dans des fuseaux horaires différents, les vols qui partent plus tôt contribueront davantage aux plafonds quotidiens de la campagne que les vols qui partent plus tard")}
                            </div>

                            <div className='d-flex gap-2'>
                                <Button type="submit"
                                    variant='primary'
                                    // style={{ background: '#f18bf1', border: 'none' }} 
                                    disabled={loadingAdd}>
                                    {loadingAdd ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {t("Chargement")}...
                                        </>
                                        : <span>{t("Ajouter")}</span>}
                                </Button>
                                <Button variant="secondary" onClick={handleCloseAddCampaignModal} disabled={loadingAdd}>
                                    {t("Annuler")}
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
                    {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddCampaignModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseAddCampaignModal}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
                </Modal>

                {/* Modal Update Campaign */}

                <Modal show={editCampaignModal} onHide={handleCloseEditCampaignModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Modifier campagne")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        style={{
                            maxHeight: '60vh',
                            overflow: 'auto'
                        }}>
                        <Form>
                            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Pick an Advertiser</Form.Label>
                            <Form.Control type="text" placeholder="Pick an Advertiser" />
                        </Form.Group> */}

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>{t("Titre campagne")}</Form.Label>
                                <Form.Control ref={nameRef} type="text" value={name} placeholder={t("Titre campagne")} onChange={e => setName(e.target.value)} />
                                {errors.Name && <Form.Text className="text-danger">{errors.Name}</Form.Text>}
                            </Form.Group>

                            {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Salesperson</Form.Label>
                            <Form.Control type="text" placeholder="Salesperson" />
                        </Form.Group> */}

                            {/* <Form.Group className="mb-3 d-flex align-items-center" controlId="dailyRevenue">
                                <Form.Check className='w-50' type="checkbox" label="Daily revenue cap" checked={checkDailyCapAmount} onChange={() => setCheckDailyCapAmount(!checkDailyCapAmount)} />
                                {checkDailyCapAmount && <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Form.Control ref={dailyCapAmountRef} className='' type="number" min={0} placeholder="Daily Cap Amount" value={dailyCapAmount} onChange={e => setDailyCapAmount(e.target.value)} />
                                    {errors.DailyCapAmount && <Form.Text className="text-danger">{errors.DailyCapAmount}</Form.Text>}
                                </div>}
                            </Form.Group>

                            <Form.Group className="mb-3 d-flex align-items-center" controlId="lifetimeRevenue">
                                <Form.Check className='w-50' type="checkbox" label="Lifetime revenue cap" checked={checkLifetimeCapAmount} onChange={() => setCheckLifetimeCapAmount(!checkLifetimeCapAmount)} />
                                {checkLifetimeCapAmount &&
                                    <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Form.Control ref={lifetimeCapAmountRef} className='' type="number" min={0} placeholder="Life time Cap Amount" value={lifetimeCapAmount} onChange={e => setLifetimeCapAmount(e.target.value)} />
                                        {errors.LifetimeCapAmount && <Form.Text className="text-danger">{errors.LifetimeCapAmount}</Form.Text>}
                                    </div>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="enableFrequency">
                                <Form.Check type="checkbox" label="Enable frequency capping" checked={checkEnableFrequencyCapping} onChange={e => setCheckEnableFrequencyCapping(!checkEnableFrequencyCapping)} />
                                {checkEnableFrequencyCapping && <div className='px-3'>
                                    <Form.Label className='m-0' style={{ fontWeight: 100 }}>Display ads from this campaign</Form.Label>
                                    <Form.Control ref={freqCapRef} type="number" placeholder="Freq Cap" value={freqCap} onChange={e => setFreqCap(e.target.value)} />
                                    {errors.FreqCap && <> <Form.Text className="text-danger">{errors.FreqCap}</Form.Text><br /></>}
                                    <Form.Label className='m-0' style={{ fontWeight: 100 }}>times per</Form.Label>
                                    <Form.Control ref={freqCapDurationRef} type="number" placeholder="Freq Cap Duration" value={freqCapDuration} onChange={e => setFreqCapDuration(e.target.value)} />
                                    {errors.FreqCapDuration && <Form.Text className="text-danger">{errors.FreqCapDuration}</Form.Text>}
                                    <Form.Select type="text" placeholder="Freq Cap Type" value={freqCapType} onChange={e => setFreqCapType(e.target.value)} >
                                        <option value={1}>Hour(s)</option>
                                        <option value={2}>Day(s)</option>
                                        <option value={3}>Minute(s)</option>
                                    </Form.Select>
                                </div>}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="lastCheck">
                                <Form.Check type="checkbox" label="This campaign should not affect the advertiser frequency cap" checked={dontAffectParentFreqCap} onChange={() => setDontAffectParentFreqCap(!dontAffectParentFreqCap)} />
                            </Form.Group> */}

                            <div className="alert alert-secondary" style={{ borderRadius: 0 }} role="alert">
                                {t("Lorsque les vols se trouvent dans des fuseaux horaires différents, les vols qui partent plus tôt contribueront davantage aux plafonds quotidiens de la campagne que les vols qui partent plus tard")}
                            </div>


                            <div className='d-flex gap-2'>
                                <Button
                                    // style={{ background: '#f18bf1', border: 'none' }} 
                                    variant='primary'
                                    onClick={() => updateCampaign(dataCampaignId?.Id)} disabled={loadingAdd}>
                                    {loadingAdd ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            {t("Chargement")}...
                                        </>
                                        : <span>{t("Modifier")}</span>}
                                </Button>
                                <Button variant="secondary" onClick={handleCloseEditCampaignModal} disabled={loadingAdd}>
                                    {t("Annuler")}
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Modal Delete Campaign */}

                <Modal show={deleteCampaignModal} onHide={handleCloseDeleteCampaignModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Supprimer la campagne")} {dataCampaignId?.Name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>

                            <span>{t("Êtes-vous sûr de vouloir supprimer cette campagne")}? {t("Toutes les annonces actives cesseront d'être diffusées")}</span>

                            
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteCampaignModal}>
                            {t("Annuler")}
                        </Button>
                        <Button
                            variant='danger'
                            onClick={() => { deleteCampaign(selectedCampaignId) }} disabled={loadingAdd}>
                            {loadingAdd ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {t("Chargement")}...
                                </>
                                : <span>{t("Supprimer")}</span>}
                        </Button>
                    </Modal.Footer>
                </Modal>

                { /* FILTER DRAWER */}
                <Offcanvas style={{ width: "250px", backgroundColor: '#232939' }} scroll={true} backdrop={false} show={showCanvas} onHide={handleCloseCanvas}>
                    <Offcanvas.Header style={{ color: 'white' }} closeButton>
                        <Offcanvas.Title style={{ fontSize: "20px" }} >{t("Filtres")}</Offcanvas.Title>
                        <style>
                            {`
      .btn-close {
        filter: invert(1);
      }
    `}
                        </style>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='py-3'>

                        <div className='d-flex' style={{ flexDirection: 'column', gap: 15 }}>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label style={{ color: 'white' }}>{t("Titre")}</Form.Label>
                                <Form.Control type="text" value={nameFilter} placeholder={t("Titre")} onChange={e => setNameFilter(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label style={{ color: 'white' }}>ID</Form.Label>
                                <Form.Control type="text" value={idFilter} placeholder="ID" onChange={e => setIDFilter(e.target.value)} />
                            </Form.Group>

                            <span style={{ color: 'white' }}>{t("Statut")} :</span>
                            <Form.Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} aria-label="Default select example">
                                <option value='' >{t("Tout")}</option>
                                <option value={true}>{t("Activée")}</option>
                                <option value={false}>{t("Désactivée")}</option>
                            </Form.Select>
                        </div>

                    </Offcanvas.Body>
                </Offcanvas>

            </div >
            :
            <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10
                }}>
                    <span>{t("Chargement")}</span>
                    <Spinner animation="grow" size='sm' />
                </div>
            </>
    )
}

export default Campaign