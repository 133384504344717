import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Form, Card, CardBody, Image, Alert, Accordion, Modal, Spinner } from "react-bootstrap";
import { useStateContext } from "../../../../context/ContextProvider";
import axiosClient, { link } from "../../../../axios-client";
import { Media } from "reactstrap";
import { FaPencilAlt } from "react-icons/fa";
import Resizer from "react-image-file-resizer";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Avatar } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import '@adzerk/management-sdk'

const styles = `
.custom-modal-size {
  max-width: 600px; 
}
`

const Add_advertiser = () => {
    const { t } = useTranslation()
    const { user, updateUser } = useStateContext()
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [tel, setTel] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState()
    const [isLoadingMdp, setIsLoadingMdp] = useState()
    const [isLoadingConfig, setIsLoadingConfig] = useState()
    const [selectedImage, setSelectedImage] = useState()
    const [selectedImg, setSelectedImg] = useState()
    const [image, setImage] = useState()
    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
    const navigate = useNavigate();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');
    const [message, setMessage] = useState('');
    const [messageEdit, setMessageEdit] = useState('');

    const [editAdvertiserModal, setEditAdvertiserModal] = useState(false);

    const [name, setName] = useState('')
    const [checkDailyCapAmount, setCheckDailyCapAmount] = useState(false)
    const [dailyCapAmount, setDailyCapAmount] = useState()
    const [checkLifetimeCapAmount, setCheckLifetimeCapAmount] = useState(false)
    const [lifetimeCapAmount, setLifetimeCapAmount] = useState()
    const [checkLimitPlacementsPerRequest, setCheckLimitPlacementsPerRequest] = useState(false)
    const [placementLimit, setPlacementLimit] = useState()
    const [checkEnableFrequencyCapping, setCheckEnableFrequencyCapping] = useState(false)
    const [freqCap, setFreqCap] = useState()
    const [freqCapDuration, setFreqCapDuration] = useState()
    const [freqCapType, setFreqCapType] = useState(1)

    const [selectedAdvertiserId, setSelectedAdvertiserId] = useState()

    const [dataAdvertiserId, setDataAdvertiserId] = useState()
    const [errors, setErrors] = useState({
        Name: '',
        DailyCapAmount: '',
        LifetimeCapAmount: '',
        PlacementLimit: '',
        FreqCap: '',
        FreqCapDuration: '',
    })

    const nameRef = useRef(null);
    const dailyCapAmountRef = useRef(null);
    const lifetimeCapAmountRef = useRef(null);
    const placementLimitRef = useRef(null);
    const freqCapRef = useRef(null);
    const freqCapDurationRef = useRef(null);

    const handleCloseEditAdvertiserModal = () => {
        setSelectedAdvertiserId()
        setName('')
        setCheckDailyCapAmount(false)
        setDailyCapAmount()
        setCheckLifetimeCapAmount(false)
        setLifetimeCapAmount()
        setCheckLimitPlacementsPerRequest(false)
        setPlacementLimit()
        setCheckEnableFrequencyCapping(false)
        setFreqCap()
        setFreqCapDuration()
        setFreqCapType(1)
        setErrors({
            Name: '',
            DailyCapAmount: '',
            LifetimeCapAmount: '',
            PlacementLimit: '',
            FreqCap: '',
            FreqCapDuration: '',
        })
        setEditAdvertiserModal(false)
    };

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });
    };
    // const handleChangeImage = async (e) => {
    //     try {
    //         const file = e.target.files[0];
    //         setSelectedImg(file)
    //         const blobImage = await resizeFile(file);

    //         const fileName = file.name; // Use the original filename
    //         const imageFile = new File([blobImage], fileName, { type: blobImage.type });

    //         setSelectedImage(imageFile);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    useEffect(() => {
        if (selectedImage) {
            setImage(URL.createObjectURL(selectedImage));  // Store image URL
        } else {
            setImage(null);  // Clear image if no image is selected
        }
    }, [selectedImage]);


    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(password)) {
            missingRequirements.push(t("caractère spécial"));
        } else if (!lowercaseRegex.test(password)) {
            missingRequirements.push(t("minuscule"));
        } else if (!uppercaseRegex.test(password)) {
            missingRequirements.push(t("majuscule"));
        } else if (!digitRegex.test(password)) {
            missingRequirements.push(t("chiffres"));
        } else if (!minLengthRegex.test(password)) {
            missingRequirements.push(t("longueur minimale de 8 caractères"));
        }

        if (missingRequirements.length > 0) {
            const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
            setPasswordStrength(missingText);
            setPasswordColor('#ff7070');
        } else {
            setPasswordStrength(t('Le mot de passe est correct!'));
            setPasswordColor('green');
        }
    }
    useEffect(() => {
        checkPasswordStrength();
    }, [password]);
    // check the password complexity
    const isPasswordValid = (pwd) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(pwd)) {
            errors.push(t('Le mot de passe doit comporter au moins 8 caractères.'));
        }

        if (!digitRegex.test(pwd)) {
            errors.push(t('Le mot de passe doit contenir au moins un chiffre.'));
        }

        if (!lowercaseRegex.test(pwd)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre minuscule.'));
        }

        if (!uppercaseRegex.test(pwd)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre majuscule.'));
        }
        if (!specialchar.test(pwd)) {
            errors.push(t('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).'));
        }
        if (pwd.length > 20) {
            errors.push(t('Le mot de passe ne doit pas dépasser 20 caractères.'));
        }

        if (errors.length > 0) {

            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(pwd);
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    const createAdvertiser = async () => {
        let newErrors = { ...errors };
        let firstErrorRef = null;
        const formData = new FormData();

        // Validate form fields
        if (name) {
            formData.append('Title', name);
            newErrors.Name = '';
        } else {
            newErrors.Name = t('Ce champ est obligatoire *');
            if (!firstErrorRef) firstErrorRef = nameRef;
        }

        if (checkDailyCapAmount) {
            if (dailyCapAmount) {
                formData.append('DailyCapAmount', dailyCapAmount);
                newErrors.DailyCapAmount = '';
            } else {
                newErrors.DailyCapAmount = t('Ce champ est obligatoire *');
                if (!firstErrorRef) firstErrorRef = dailyCapAmountRef;
            }
        } else {
            formData.append('DailyCapAmount', 0);
            newErrors.DailyCapAmount = '';
        }

        if (checkLifetimeCapAmount) {
            if (lifetimeCapAmount) {
                formData.append('LifetimeCapAmount', lifetimeCapAmount);
                newErrors.LifetimeCapAmount = '';
            } else {
                newErrors.LifetimeCapAmount = t('Ce champ est obligatoire *');
                if (!firstErrorRef) firstErrorRef = lifetimeCapAmountRef;
            }
        } else {
            formData.append('LifetimeCapAmount', 0);
            newErrors.LifetimeCapAmount = '';
        }

        if (checkLimitPlacementsPerRequest) {
            if (placementLimit) {
                formData.append('PlacementLimit', placementLimit);
                newErrors.PlacementLimit = '';
            } else {
                newErrors.PlacementLimit = t('Ce champ est obligatoire *');
                if (!firstErrorRef) firstErrorRef = placementLimitRef;
            }
        } else {
            formData.append('PlacementLimit', 0);
            newErrors.PlacementLimit = '';
        }

        if (checkEnableFrequencyCapping) {
            if (freqCap) {
                formData.append('FreqCap', freqCap);
                newErrors.FreqCap = '';
            } else {
                newErrors.FreqCap = t('Ce champ est obligatoire *');
                if (!firstErrorRef) firstErrorRef = freqCapRef;
            }

            if (freqCapDuration) {
                formData.append('FreqCapDuration', freqCapDuration);
                newErrors.FreqCapDuration = '';
            } else {
                newErrors.FreqCapDuration = t('Ce champ est obligatoire *');
                if (!firstErrorRef) firstErrorRef = freqCapDurationRef;
            }

            if (freqCapType) {
                formData.append('FreqCapType', freqCapType);
            }
        } else {
            formData.append('FreqCap', 0);
            formData.append('FreqCapDuration', 0);
            formData.append('FreqCapType', 0);
            newErrors.FreqCap = '';
            newErrors.FreqCapDuration = '';
        }

        setErrors(newErrors);

        // Scroll to the first error if any
        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        formData.append('IsActive', true);

        if (!firstErrorRef) {
            try {
                const response = await axiosClient.post(`add-advertiser/`, formData);

                return response.data;

            } catch (error) {
                // console.error("Error creating advertiser:", error);
                return null; // Return null if an error occurs
            }
        }
    };


    const onSubmit = async (ev) => {
        setIsLoading(true);

        const check = await axiosClient.get(`email_exists/${email}/`);

        if (!isFormFilled) {
            setMessage(t('Veuillez remplir les champs correctement'));
            setIsLoading(false);
            window.scrollTo(0, 0)
            return;
        } else if (!emailRegex.test(email)) {
            setMessage(t("Veuillez entrer un email valide."));
            setIsLoading(false);
            window.scrollTo(0, 0)
            return;
        } else if (check.data.exists) {
            setIsLoading(false);
            setMessage(t("Cet email existe déja"));
            window.scrollTo(0, 0)
            return;
        } else if (password !== confirmPassword) {
            setMessage(t("Les mots de passe ne correspondent pas"));
            setIsLoading(false);
            window.scrollTo(0, 0)
            return;
        } else if (passwordStrength !== 'Fort' && password.length > 0 && !isPasswordValid(password)) {
            setIsLoading(false);
            return;
        }

        try {

            const advertiser = await createAdvertiser();

            if (!advertiser) {
                // setMessage("Failed to create advertiser");
                setIsLoading(false);
                return;
            }

            const payload = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                tel: tel,
                image: selectedImage,
                Advertiser: advertiser.Id, // Use the returned advertiser's ID
                password: password,
            };

            const formData = new FormData()
            formData.append('first_name', firstName)
            formData.append('last_name', lastName)
            formData.append('email', email)
            formData.append('Advertiser_title', name)
            formData.append('tel', tel)
            { selectedImage && formData.append('image', selectedImage) }
            formData.append('Advertiser', advertiser.Id)
            formData.append('password', password)

            axiosClient.post(`agences/`, formData)
                .then(({ data }) => {
                    navigate('/advertisers');
                    setIsLoading(false);
                })
                .catch((err) => {
                    const response = err.response;
                    setMessage(response.data);
                    setIsLoading(false);
                });
        } catch (error) {
            // console.error("Error creating advertiser:", error);
            // setMessage("Failed to create advertiser");
            setIsLoading(false);
        }
    };


    const isFormFilled = firstName.trim() && lastName.trim() && name.trim() && email.trim() && password.trim() && confirmPassword.trim();

    return (
        <React.Fragment>
            <style>{styles}</style>
            <ToastContainer />

            <h4 className="px-2 my-2 my-sm-4">{t("Ajouter une agence")}</h4>

            <Card className="container py-3">
                {/* <h4 className=" px-2 px-sm-5 my-2 my-sm-4">Ajouter un annonceur</h4> */}

                <CardBody className="container px-3 px-sm-5 py-2">

                    <Form >
                        <Row >
                            <Col style={{ alignItems: "center", justifyContent: "center", display: "flex" }} md="4" xl="3">
                                <Media className="mb-3 mb-md-0" style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%", alignContent: "center" }} as={Col} md="12">
                                    <div className="position-relative" style={{ width: 180, height: 180, marginRight: 10 }}>
                                        <Avatar
                                            style={{ objectFit: "cover", borderRadius: "5%" }}
                                            sx={{ width: 180, height: 180 }}
                                            src={image}
                                            variant="square"
                                        />
                                        <label htmlFor="imageInput" className="position-absolute" style={{
                                            cursor: 'pointer',
                                            bottom: -15,
                                            right: -10,
                                            border: "3px solid white",
                                            borderRadius: "50%"
                                        }}>
                                            <input
                                                id="imageInput"
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                onChange={(e) => {
                                                    setSelectedImage(e.target.files[0])
                                                }
                                                }
                                            />
                                            <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#232939', borderRadius: '50%', width: 30, height: 30 }}>
                                                <FaPencilAlt size={15} color='#fff' />
                                            </div>
                                        </label>
                                    </div>

                                </Media>
                            </Col>
                            <Col md='8' xl="9">
                                {message && <Alert variant={"danger"}>{message}</Alert>}
                                <Row className="mb-3 gap-2 gap-md-0">

                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>{t("Nom")}</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder={t("Entrez le nom")}
                                            defaultValue={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>{t("Prénom")}</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder={t("Entrez le prénom")}
                                            defaultValue={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3 gap-2 gap-md-0">

                                    <Form.Group as={Col} md="6" >
                                        <Form.Label>{t("Nom d'agence")}</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder={t("Entrez le nom d'agence")}
                                            defaultValue={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustomTel">
                                        <Form.Label>{t("Numéro de téléphone")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t("Entrez le numéro de téléphone")}
                                            defaultValue={tel}
                                            onChange={(e) => setTel(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3 gap-2 gap-md-0">
                                    <Form.Group as={Col} md="12" controlId="validationCustomEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder={t("Entrez l'email")}
                                            defaultValue={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Form.Group>


                                </Row>
                                <Row className="mb-3 gap-2 gap-md-0">

                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>{t("Mot de passe")}</Form.Label>
                                        <Form.Control
                                            required
                                            type="password"
                                            placeholder={t("Entrez le mot de passe")}
                                            defaultValue={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>{t("Confirmez le mot de passe")}</Form.Label>
                                        <Form.Control
                                            required
                                            type="password"
                                            placeholder={t("Confirmez le mot de passe")}
                                            defaultValue={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>
                                {password && password.length > 0 ?
                                    <div style={{ color: passwordColor, marginBottom: 10 }} >{`${passwordStrength}`}</div>
                                    : ""}

                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
            <Card className="container   py-3">
                <h4 className="px-2 px-sm-5 my-2 my-sm-4">{t("Détails annonceur")}</h4>

                <CardBody className="container px-3 px-sm-5 py-2">
                    <Form >
                        <div className="mx-5" style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                            <Form.Group className="mb-3 d-flex align-items-center" controlId="dailyRevenue">
                                <Form.Check className='w-50' type="checkbox" label={t("Plafond de revenus quotidiens")} checked={checkDailyCapAmount} onChange={() => setCheckDailyCapAmount(!checkDailyCapAmount)} />
                                {checkDailyCapAmount && <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Form.Control ref={dailyCapAmountRef} className='' type="number" min={0} placeholder="" value={dailyCapAmount} onChange={e => setDailyCapAmount(e.target.value)} />
                                    {errors.DailyCapAmount && <Form.Text className="text-danger">{errors.DailyCapAmount}</Form.Text>}
                                </div>}
                            </Form.Group>
                            <Form.Group className="mb-3 d-flex align-items-center" controlId="lifetimeRevenue">
                                <Form.Check className='w-50' type="checkbox" label={t("Plafond de revenus à vie")} checked={checkLifetimeCapAmount} onChange={() => setCheckLifetimeCapAmount(!checkLifetimeCapAmount)} />
                                {checkLifetimeCapAmount &&
                                    <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Form.Control ref={lifetimeCapAmountRef} className='' type="number" min={0} placeholder="" value={lifetimeCapAmount} onChange={e => setLifetimeCapAmount(e.target.value)} />
                                        {errors.LifetimeCapAmount && <Form.Text className="text-danger">{errors.LifetimeCapAmount}</Form.Text>}
                                    </div>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3 d-flex align-items-center" controlId="placementLimit">
                                <Form.Check className='w-50' type="checkbox" label={t("limite des placements par demande")} checked={checkLimitPlacementsPerRequest} onChange={() => setCheckLimitPlacementsPerRequest(!checkLimitPlacementsPerRequest)} />
                                {checkLimitPlacementsPerRequest &&
                                    <div className='w-50' style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Form.Control ref={placementLimitRef} className='' type="number" min={0} placeholder="" value={placementLimit} onChange={e => setPlacementLimit(e.target.value)} />
                                        {errors.PlacementLimit && <Form.Text className="text-danger">{errors.PlacementLimit}</Form.Text>}
                                    </div>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="enableFrequency">
                                <Form.Check className="mb-3" type="checkbox" label={t("Activer la limitation de fréquence")} checked={checkEnableFrequencyCapping} onChange={e => setCheckEnableFrequencyCapping(!checkEnableFrequencyCapping)} />
                                {checkEnableFrequencyCapping &&
                                    <div className='px-3 d-flex' style={{ flexDirection: 'column', gap: 10 }}>
                                        <div>
                                            <Form.Label className='m-0 mb-1' style={{ fontWeight: 100 }}>{t("Afficher les annonces de cet annonceur")}</Form.Label>
                                            <Form.Control ref={freqCapRef} type="number" placeholder="" value={freqCap} onChange={e => setFreqCap(e.target.value)} />
                                            {errors.FreqCap && <> <Form.Text className="text-danger">{errors.FreqCap}</Form.Text><br /></>}
                                        </div>
                                        <div>
                                            <Form.Label className='m-0 mb-1' style={{ fontWeight: 100 }}>{t("fois par")}</Form.Label>
                                            <Form.Control ref={freqCapDurationRef} type="number" placeholder="" value={freqCapDuration} onChange={e => setFreqCapDuration(e.target.value)} />
                                            {errors.FreqCapDuration && <Form.Text className="text-danger">{errors.FreqCapDuration}</Form.Text>}
                                            <Form.Select type="text" placeholder="" value={freqCapType} onChange={e => setFreqCapType(e.target.value)} >
                                                <option value={1}>{t("Heure(s)")}</option>
                                                <option value={2}>{t["Jour(s)"]}</option>
                                                <option value={3}>{t("Minute(s)")}</option>

                                            </Form.Select>
                                        </div>


                                    </div>}
                            </Form.Group>
                        </div>




                    </Form>
                </CardBody>
            </Card>
            <div style={{ display: 'flex', justifyContent: "end", width: "100%" }} className="container p-0">
                <Button isLoading={isLoading} disabled={!isFormFilled} size="lg" variant="primary" onClick={() => onSubmit()}>
                    {isLoading ? <Spinner size="sm" /> : t('Ajouter')}

                </Button>
            </div>


        </React.Fragment>

    );
};

export default Add_advertiser;
