import { Avatar, Divider, Pagination, Stack, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Form, Modal, Button, Image, Row, Col, Alert, Accordion, Offcanvas, InputGroup, Spinner } from 'react-bootstrap'
import { Media, Table } from 'reactstrap'
import { RiImageAddFill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";
import axiosClient, { pagination_size } from '../../../../axios-client';
import { useStateContext } from '../../../../context/ContextProvider';

const styles = `
.custom-modal-size {
  max-width: 600px; 
}
`
function Clients() {
  const { user } = useStateContext()
  const [showCanvas, setShowCanvas] = useState(false);
  const location = useLocation()

  const [loading, setLoading] = useState()

  const handleCloseCanvas = () => setShowCanvas(false);
  const toggleShowCanvas = () => setShowCanvas(true);


  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation()
  const [paginationData, setPaginationData] = useState()
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEtat, setSelectedEtat] = useState(null);
  const [id, setId] = useState(null);
  const [userEdit, setUserEdit] = useState();

  const [image, setImage] = useState()
  const [selectedImage, setSelectedImage] = useState()

  const [data, setData] = useState([])


  ////////////////////////////////the size of the table//////////////////////////////
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const PAGE_SIZE = pagination_size;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////



  useEffect(() => {
    if (selectedImage) {

      setImage(URL.createObjectURL(selectedImage))
    }
  }, [selectedImage])

  
  useEffect(() => {
    setLoading(true)
    axiosClient.get(`/clients/getAllClients/?page=${page + 1}&page_size=${pageSize}&etat=${selectedEtat}&search_term=${searchTerm}`)
        .then((response) => {
          let filteredData = response.data.results;
          setPaginationData(response.data);
          setTotalPages(Math.ceil(response.data.count / pageSize));
          setData(filteredData);
          setLoading(false)
        })
        .catch((error) => {
          // console.error(error);
          setLoading(false)
        });

  }, [page, pageSize, selectedEtat, searchTerm])




  const handleEtatClick = (id, etat) => {
    const formData = new FormData();
    formData.append("etat", !etat);

    axiosClient.put(`/update_client_etat/${id}/`, formData)
      .then((response) => {
        setData(rows => rows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              etat: !etat,
            };
          }
          return row;
        }));
      })
      .catch((error) => console.error(error));
    if (!etat) {

      toast.success(t("le compte est activé"), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    else {

      toast.error(t("le compte est désactivé"), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  }


  return (
<>
    {!loading ?
      <div>
          <>
            <ToastContainer />

            <style>{styles}</style>
            <div className="d-flex justify-content-between align-items-center my-3 mb-4">
              <h3>Clients</h3>
              <div>        <Button variant="primary" size='md' style={{ fontSize: "16px" }} onClick={toggleShowCanvas} className="me-2">
                <FaFilter />
              </Button>
                {/* <div></div> */}

              </div></div>
            <Table style={{ borderColor: "lightgray" }} responsive>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>{t('Activité')}</th>
                  <th>{t("Nom complet")}</th>
                  <th>email</th>
                  <th>{t("Agence")}</th>
                  <th>{t("Téléphone")}</th>
                  <th>{t("Date de création")}</th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody >
                {(!searchTerm && selectedEtat === null && paginationData?.length === 0) &&
                  <tr>
                    <td colSpan={9}>{t("Il n'y a pas encore de données")}</td>
                  </tr>
                }
                {(paginationData?.length !== 0) &&
                  data.map((user) => (
                    <tr key={user.id} className="align-middle">
                      <th scope="row">
                      <Avatar style={{ objectFit: "cover", borderRadius: "10%" }} variant='square' width={45} height={45} src={user.image} />
                      </th>
                      <th  >
                        <Form>
                          <Form.Check
                            checked={user.etat}
                            type="switch"
                            id="custom-switch"
                            label=""
                            onChange={() => handleEtatClick(user.id, user.etat)}

                          />

                        </Form>
                      </th>

                      <td>
                        <Link to={`/advertiser-campaigns/${user?.AdvertiserId}/${user.id}`} className=" waves-effect" onMouseEnter={e => e.target.style.textDecoration = 'underline'} onMouseLeave={e => e.target.style.textDecoration = 'none'}>
                          <div style={{ display: "flex", flexDirection: 'column' }}>
                            <span>{user.first_name}</span>
                            <span> {user.last_name}</span>
                          </div>
                        </Link>

                      </td>
                      <td>{user.email}</td>
                      <td>{user.AdvertiserId}</td>
                      <td>{user.tel}</td>
                      <td>{new Date(user.date_creation).toLocaleDateString(t('fr-FR'))}</td>

                    </tr>
                  ))}


                {(((searchTerm || selectedEtat !== null) && paginationData?.length === 0)) && (
                  <tr>
                    <td colSpan={9}>{t("Aucune ligne correspondante n'a été trouvée.")}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {paginationData?.length !== 0 &&
              <Row>
                <Col lg={12} className="d-flex justify-content-end">

                  <TablePagination
                    component="div"
                    count={totalPages * pageSize} // Total number of items
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handlePageSizeChange}

                    rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                    showFirstButton={true}
                    showLastButton={true}
                    sx={{
                      ".MuiTablePagination-displayedRows": {
                        margin: 0
                      },
                      ".MuiTablePagination-selectLabel": {
                        display: 'none',
                        margin: 0
                      },
                      ".MuiSelect-select": {
                        backgroundColor: "#ededed"
                      }
                    }}
                  />
                </Col>
              </Row>

            }
          </>

      </div>
      :
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10
      }}>
        <span>{t("Chargement")}</span>
        <Spinner animation="grow" size='sm' />
      </div>}
      <Offcanvas style={{ width: "250px", backgroundColor: '#232939' }} scroll={true} backdrop={false} show={showCanvas} onHide={handleCloseCanvas}>
          <Offcanvas.Header style={{ color: 'white' }} closeButton>
            <Offcanvas.Title style={{ fontSize: "20px" }} >{t("Filtres")}</Offcanvas.Title>
            <style>
              {`
      .btn-close {
        filter: invert(1);
      }
    `}
            </style>
          </Offcanvas.Header>
          <Offcanvas.Body className='py-3'>

            <div className='d-flex' style={{ flexDirection: 'column', gap: 15 }}>
              <InputGroup >
                <InputGroup.Text style={{ background: "white", width: 'fit-content', borderRight: "none" }} >
                  <IoSearchSharp style={{ padding: 0, margin: 0 }} />
                </InputGroup.Text>

                <Form.Control
                  value={searchTerm}
                  onChange={(e) => {
                    setPage(0)
                    setSearchTerm(e.target.value)
                  }}
                  style={{ paddingLeft: 0, marginLeft: 0, background: "white", borderLeft: "none" }}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder={t('Recherche')}
                />
              </InputGroup>
              <span style={{ color: 'white' }}>{t("Activité")} :</span>
              <Form.Select value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')} aria-label="Default select example">
                <option value='' >{t("Sélectionnez l'activité")}</option>
                <option value="true">{t("Activé")}</option>
                <option value="false">{t("Désactivé")}</option>
              </Form.Select>
            </div>

          </Offcanvas.Body>
        </Offcanvas>
      </>



  )
}

export default Clients