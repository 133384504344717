import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom'; // Assuming you're using React Router

const PaymentConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {t}=useTranslation()
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirectStatus = params.get('redirect_status');

    // Quickly check the payment status and navigate back
    if (redirectStatus === 'succeeded') {
      navigate(-2);  // Immediately go back to the previous page
    }
  }, [location, navigate]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h5>{t("Traitement de paiement")}...</h5> {/* Can use a spinner here instead */}
    </div>
  );
};

export default PaymentConfirmation;