import { Avatar, CircularProgress, Divider, Pagination, Stack, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Form, Modal, Button, Image, Row, Col, Alert, Accordion, Offcanvas, InputGroup, Spinner } from 'react-bootstrap'
import { Media, Table } from 'reactstrap'
import { RiImageAddFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../context/ContextProvider';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

import 'react-toastify/dist/ReactToastify.css';
import axiosClient, { pagination_size } from '../../../axios-client';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from "react-icons/io5";
import { FaFileDownload, FaFilter } from "react-icons/fa";
import { fetchPaiementsFailure, fetchPaiementsRequest, fetchPaiementsSuccess } from '../../store/actions/PaiementActions';
import { fetchDevisFailure, fetchDevisRequest, fetchDevisSuccess } from '../../store/actions/DevisActions';
import { useDispatch, useSelector } from 'react-redux';
import { Modal as FactureModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import logoImage from '../../../assets/images/favicon.png'; // Adjust the path based on your project structure
import { Document, Page, Text, View, StyleSheet, Image as ImageP, Font, pdf } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
const styles = `
.custom-modal-size {
  max-width: 600px; 
}
`
Font.register({
  family: 'Helvetica-Bold',
  // src: HelveticaBoldFont,
});

Font.register({
  family: 'Times-Roman',
  // src: TimesRomanFont,
});

const stylesP = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 30,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  logo: {
    width: 100,
    height: 100,
  },
  title: {
    fontSize: 20,
    marginTop: 20,
    fontFamily: 'Helvetica-Bold',
  },
  content: {
    marginTop: 40,
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'baseline',
  },
  contentX: {
    marginTop: 25,
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'baseline',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 30
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
  footer: {
    position: 'absolute',
    left: 30,
    marginTop: 400,
    right: 30,
    textAlign: 'center',
    fontSize: 15,
  },

});
function Payment() {
  const { user } = useStateContext()



  const [showCanvas, setShowCanvas] = useState(false);

  const [loading, setLoading] = useState()


  const handleCloseCanvas = () => setShowCanvas(false);
  const toggleShowCanvas = () => setShowCanvas(true);


  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation()
  const [paginationData, setPaginationData] = useState()
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEtat, setSelectedEtat] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [title, setTitle] = useState("");
  const [data, setData] = useState([])


  ////////////////////////////////the size of the table//////////////////////////////
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const PAGE_SIZE = pagination_size;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////



  const [loadingFilter, setLoadingFilter] = useState()
  const getData = async () => {
    try {
      setLoadingFilter(true)
      await axiosClient.get(`/paiements/getUserPayment/?page=${page + 1}&page_size=${pageSize}&user=${user.id}&type=${selectedType}&search_term=${searchTerm}`)
        .then((response) => {
          let filteredData = response.data.results;
          setPaginationData(response.data);
          setTotalPages(Math.ceil(response.data.count / pageSize));
          setData(filteredData);
          setLoading(false)
          setLoadingFilter(false)

        })
        .catch((error) => {
          // console.error(error);
          setLoading(false)
          setLoadingFilter(false)

        });
    } catch (error) {
      // console.error('ERROR : ', error);
      setLoading(false)
      setLoadingFilter(false)


    }
  }
  const getAdvertiserWithId = async (id) => {
    try {
      await axiosClient.get(`get-advertiser-with-id/${id}/`).then(res => {
        if (res.data.Title) {
          return res.data.Title
        }

      })
    } catch (error) {
      // console.error('Failed to fetch advertiser data:', error);
    }
  }
  useEffect(() => {
    setLoading(true)
    getData()
  }, [])

  useEffect(() => {
    getData()
    // if (user?.role === "Agency") {
    //   getAdvertiserWithId(user?.advertiser)
    // }

  }, [user, page, pageSize, selectedType, searchTerm])


  const [pdfUrl, setPdfUrl] = useState('');
  const { devis } = useSelector(state => state.devis);
  const { paiements } = useSelector(state => state.paiements);

  const toggleModal = (flight,
    montant, firstName, lastName, advertiserTitle, id, logo) => {
    if (!modalOpen) {
      generatePDF(flight, montant, firstName, lastName, advertiserTitle, id, logo);
    }
    setModalOpen(!modalOpen);
  };
  const notifySuccess = (text) => toast.success(text, {
    position: "top-center",
    autoClose: 400,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  });

  const [devis_, setDevis_] = useState(null);
  const [paiements_, setPaiement_] = useState(null);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDevisRequest());

    axiosClient.get(`devis/`)
      .then(response => {
        dispatch(fetchDevisSuccess(response.data.results));

        setDevis_(response.data.results)
        // setTotalPages(Math.ceil(response.data.count / 10));
      })
      .catch(error => {
        dispatch(fetchDevisFailure(error.message));
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPaiementsRequest());

    axiosClient.get(`paiements/`)
      .then(response => {
        dispatch(fetchPaiementsSuccess(response.data.results));

        setPaiement_(response.data.results)
        // setTotalPages(Math.ceil(response.data.count / 10));
      })
      .catch(error => {
        dispatch(fetchPaiementsFailure(error.message));
      });
  }, [dispatch]);



  const combineDevisAndPaiements = (devisList, paiementsList) => {
    // Create a map for devis with its paiements
    const devisMap = new Map();

    // Initialize the devis map with each devis and an empty paiements array
    devisList.forEach(devis => {
      devisMap.set(devis.id, {
        ...devis,
        paiements: [] // Initialize with empty paiements
      });
    });

    // Populate the paiements in the correct devis entry
    paiementsList.forEach(paiement => {
      const devisId = paiement.devis;
      if (devisMap.has(devisId)) {
        devisMap.get(devisId).paiements.push(paiement);
      }
    });

    // Convert the map values to an array
    return Array.from(devisMap.values());
  };

  const PDFDocument = ({ adGroup, montant, firstName, lastName, advertiserTitle, id, logo }) => {
    const { t } = useTranslation();
    const now = new Date();
    const formattedDate = now.toISOString().split('T')[0];
    const formattedTime = now.toTimeString().split(' ')[0];
    const devisId = `F${id.toString().padStart(6, '0')}`;

    return (
      <Document>
        <Page size="A4" style={stylesP.page}>
          <View style={stylesP.header}>
            <Text style={stylesP.title}>{t("Facture")} : {devisId}</Text>
            <ImageP style={stylesP.logo} src={logo} />
          </View>
          <View style={stylesP.content}>
            <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 16 }}>{t("Client")} : </Text>
            <Text style={{ fontFamily: 'Times-Roman', fontSize: 16 }}>{firstName} {lastName}</Text>
          </View>
          <View style={stylesP.table}>
            <View style={[stylesP.tableRow, { backgroundColor: 'rgb(173, 216, 230)' }]}>
              <View style={stylesP.tableCol}>
                <Text style={stylesP.tableCell}>{t("Groupe d'annonces ")}</Text>
              </View>
              <View style={stylesP.tableCol}>
                <Text style={stylesP.tableCell}>Date</Text>
              </View>
              <View style={stylesP.tableCol}>
                <Text style={stylesP.tableCell}>{t("Montant (€)")}</Text>
              </View>
            </View>
            <View style={stylesP.tableRow}>
              <View style={stylesP.tableCol}>
                <Text style={stylesP.tableCell}>{adGroup}</Text>
              </View>
              <View style={stylesP.tableCol}>
                <Text style={stylesP.tableCell}>{formattedDate}</Text>
              </View>
              <View style={stylesP.tableCol}>
                <Text style={stylesP.tableCell}>{montant}</Text>
              </View>
            </View>
          </View>

          <View style={stylesP.contentX}>
            <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 16 }}>{t("Généré le")} : </Text>
            <Text style={{ fontFamily: 'Times-Roman', fontSize: 16 }}> {formattedDate} at {formattedTime}</Text>
          </View>
          <Text style={stylesP.footer}>
            {t("Merci pour votre confiance ! la facture a été généré par AD Server")}
          </Text>
        </Page>
      </Document>
    );
  };
  const [pdfSent, setPdfSent] = useState(false);
  const [generatedPdfBlob, setGeneratedPdfBlob] = useState(null); // State to store the generated PDF blob
  const [id_, setId_] = useState(null); // Add state for id

  const generatePDF = async (adGroup, montant, firstName, lastName, advertiserTitle, id, logo) => {
    if (!adGroup) {
      alert("No data to generate PDF.");
      return;
    }
    setId_(id); // Update id in state

    try {
      const filename = `facture-${new Date().toISOString().split('T')[0]}.pdf`;
      const logoImage = logo;

      // Generate PDF blob
      const blob = await pdf(
        <PDFDocument
          adGroup={adGroup}
          montant={montant}
          firstName={firstName}
          lastName={lastName}
          advertiserTitle={advertiserTitle}
          id={id}
          logo={logoImage}
        />
      ).toBlob();

      // Store the generated PDF blob
      setGeneratedPdfBlob(blob);

      // Update PDF URL to allow viewing the regenerated PDF
      setPdfUrl(URL.createObjectURL(blob));

    } catch (error) {
      // console.error("Error generating PDF:", error);
      alert("Error generating PDF. Please try again.");
    }
  };

  const [pdfLoad, setPdfLoad] = useState(false); // State for loading



  const [lastSendTime, setLastSendTime] = useState(0); // Timestamp of the last email sent



  const downloadPDF = async () => {
    if (!generatedPdfBlob) {
      alert('No PDF available to download.');
      return;
    }

    const filename = `facture-${new Date().toISOString().split('T')[0]}.pdf`;

    // Create a download link for the PDF
    const link = document.createElement('a');
    link.href = URL.createObjectURL(generatedPdfBlob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Check the current time and last send time for email
    const currentTime = Date.now();
    const interval = 3000; // 4 seconds in milliseconds

    if (pdfSent && (currentTime - lastSendTime < interval)) {
      alert('Please wait before sending the email again.');
      return;
    }

    // Set loading to true
    setPdfLoad(true); // Set the loading state

    // Now, send the email with the PDF
    const formData = new FormData();
    formData.append('pdf', generatedPdfBlob, filename);
    formData.append('email', user && user.email);
    formData.append('devisId', `F${id_.toString().padStart(6, '0')}`);

    try {
      const response = await axiosClient.post('/send-pdf-email/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200 && response.data.message) {
        alert(t("Un e-mail a été envoyé à votre adresse email avec votre facture !"));
        setPdfSent(true); // Mark that the email has been sent
        setLastSendTime(currentTime); // Update the last send time
      } else {
        // console.error('PDF send failed:', response);
        alert(t('Failed to send PDF. Please try again.'));
      }
    } catch (error) {
      // console.error("Error sending PDF via email:", error);
      alert("Error sending PDF via email. Please try again.");
    } finally {
      // Reset loading state after 5 seconds
      setTimeout(() => {
        setPdfLoad(false); // Reset loading to false
      }, 3000); // Adjust the timeout duration as needed
    }
  };



  const [modalOpen, setModalOpen] = useState(false);

  // Handle save changes
  const handleSaveChanges = () => {
    if (pdfUrl) {
      downloadPDF(); // Pass necessary parameters
    } else {
      alert('No PDF available to download.');
    }
  };




  return (
    !loading ?
      <div>
        {!loadingFilter ?
          <>
            <ToastContainer
              position="top-center"
              autoClose={400}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              transition="bounce"
            />

            <style>{styles}</style>
            <div className="row d-flex justify-content-between align-items-center my-3 mb-4">
              <h3 className=' col-12 col-sm-6'>{t("Liste des paiements")}</h3>
              <div className="col-12 col-sm-6 d-flex justify-content-sm-end justify-content-end mt-2 mt-sm-0">
                <Button variant="primary" size='md' style={{ fontSize: "16px" }} onClick={toggleShowCanvas} className="me-2">
                  <FaFilter />
                </Button>

              </div>
            </div>
            <Table style={{ borderColor: "lip://locaghtgray" }} responsive>
              <thead>
                <tr>
                  <th>{t("Groupe d'annonces")}</th>
                  <th>{t('Montant')}</th>
                  {user.role !== "Client" && <th>{t("Utilisateur")}</th>}

                  <th>{t("Date de création")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody >
                {(!searchTerm && !selectedType && paginationData?.length === 0) &&
                  <tr>
                    <td colSpan={9}>{t("Il n'y a pas encore de données")}</td>
                  </tr>
                }
                {(paginationData?.length !== 0) &&
                  data.map((payment) => (
                    <tr key={payment.id} className="align-middle">
                      <td>{payment.flight}</td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>{payment.montant} $</td>
                      {user.role !== "Client" &&
                        <td>{
                          payment.user_role === "Agency" ? t("Agence") : "Client"}
                          {payment.user_role === "Client"
                            ? <span style={{ color: "#ee7625", fontSize: "15px" }}> : {payment.user_firstName} {payment.user_lastName}</span>
                            : (payment.user_role === "Agency" && user?.role === "Admin")
                              ?
                              <span style={{ color: "#7a6fbe", fontSize: "15px" }}> : {payment.advertiser_title} </span>
                              : ""
                          }
                        </td>
                      }
                      {/* <td>
                        <div style={{ display: "flex", flexDirection: 'column' }}>
                         
                          <>
                            <span>{payment.user_firstName} </span>
                            <span> {payment.user_lastName}</span>
                          </>
                         

                        </div>
                      </td> */}

                      <td>
                        {new Date(payment.date).toLocaleDateString(t('fr-FR'))}
                      </td>
                      <td>

                        <Button onClick={() => toggleModal(payment.flight, payment.montant,
                          payment.user_firstName, payment.user_lastName, payment.advertiser_title, payment.id, logoImage)}
                          variant='outline-warning'
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: 'flex',
                            gap: 3
                          }}>
                          <FaFileDownload /> Facture
                        </Button>
                      </td>
                    </tr>
                  ))}


                <ToastContainer
                  position="top-center"
                  autoClose={400}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                  transition="bounce"
                />


                <FactureModal isOpen={modalOpen} toggle={toggleModal} size="lg">
                  <ModalHeader toggle={toggleModal}>Exporter  votre facture</ModalHeader>
                  <ModalBody>
                    <div style={{ height: '70vh' }}>
                      {pdfUrl && (
                        <iframe
                          src={pdfUrl}
                          style={{ width: '100%', height: '70vh' }}
                          frameBorder="0"
                        />
                      )}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>
                      Close
                    </Button>
                    <Button disabled={pdfLoad} color="primary" onClick={downloadPDF}>
                      {pdfLoad ? <CircularProgress size={16} style={{ color: 'white' }} /> : 'Download'}
                    </Button>




                  </ModalFooter>
                </FactureModal>


                {(((searchTerm || selectedType) && paginationData?.length === 0)) && (
                  <tr>
                    <td colSpan={9}>{t("Aucune ligne correspondante n'a été trouvée.")}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {paginationData?.length !== 0 &&
              <Row>
                <Col lg={12} className="d-flex justify-content-end">

                  <TablePagination
                    component="div"
                    count={totalPages * pageSize} // Total number of items
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handlePageSizeChange}

                    rowsPerPageOptions={[10, 20, 30, 40, 50]} // Define page size options
                    showFirstButton={true}
                    showLastButton={true}
                    sx={{
                      ".MuiTablePagination-displayedRows": {
                        margin: 0
                      },
                      ".MuiTablePagination-selectLabel": {
                        display: 'none',
                        margin: 0
                      },
                      ".MuiSelect-select": {
                        backgroundColor: "#ededed"
                      }
                    }}
                  />
                </Col>
              </Row>

            }
          </>
          :
          <>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10
            }}>
              <span>{t("Chargement")}</span>
              <Spinner animation="grow" size='sm' />
            </div>
          </>
        }



        <Offcanvas style={{ width: "250px", backgroundColor: '#232939' }} scroll={true} backdrop={false} show={showCanvas} onHide={handleCloseCanvas}>
          <Offcanvas.Header style={{ color: 'white' }} closeButton>
            <Offcanvas.Title style={{ fontSize: "20px" }} >{t("Filtres")}</Offcanvas.Title>
            <style>
              {`
      .btn-close {
        filter: invert(1);
      }
    `}
            </style>
          </Offcanvas.Header>
          <Offcanvas.Body className='py-3'>

            <div className='d-flex' style={{ flexDirection: 'column', gap: 15 }}>
              <InputGroup >
                <InputGroup.Text style={{ background: "white", width: 'fit-content', borderRight: "none" }} >
                  <IoSearchSharp style={{ padding: 0, margin: 0 }} />
                </InputGroup.Text>

                <Form.Control
                  value={searchTerm}
                  onChange={(e) => {
                    setPage(0)
                    setSearchTerm(e.target.value)
                  }}
                  style={{ paddingLeft: 0, marginLeft: 0, background: "white", borderLeft: "none" }}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  placeholder={t('Recherche')}
                />
              </InputGroup>
              {user?.role !== "Client" &&
                <>
                  <span style={{ color: 'white' }}>{t("Par")} :</span>
                  <Form.Select value={selectedType} onChange={(e) => setSelectedType(e.target.value)} aria-label="Default select example">
                    <option value='' >{t("Sélectionnez un type")}</option>
                    <option value="Client">{t("Client")}</option>
                    <option value="Agency">{t("Agence")}</option>
                  </Form.Select>
                </>}

            </div>

          </Offcanvas.Body>
        </Offcanvas>
      </div>
      :
      <>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 10
        }}>
          <span>{t("Chargement")}</span>
          <Spinner animation="grow" size='sm' />
        </div>
      </>
  )
}

export default Payment