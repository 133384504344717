const DataTimeZone = [
    // {"value": "Etc/GMT+12", "label": "UTC-12:00"},
    // {"value": "Etc/GMT+11", "label": "UTC-11:00"},
    {"value": "Pacific/Honolulu", "label": "UTC-10:00"},
    {"value": "Pacific/Marquesas", "label": "UTC-09:30"},
    {"value": "America/Anchorage", "label": "UTC-09:00"},
    {"value": "America/Los_Angeles", "label": "UTC-08:00"},
    {"value": "America/Denver", "label": "UTC-07:00"},
    {"value": "America/Chicago", "label": "UTC-06:00"},
    {"value": "America/New_York", "label": "UTC-05:00"},
    {"value": "America/Santiago", "label": "UTC-04:00"},
    {"value": "America/St_Johns", "label": "UTC-03:30"},
    {"value": "America/Argentina/Buenos_Aires", "label": "UTC-03:00"},
    // {"value": "Etc/GMT+2", "label": "UTC-02:00"},
    // {"value": "Etc/GMT+1", "label": "UTC-01:00"},
    {"value": "UTC", "label": "UTC+00:00"},
    {"value": "Europe/London", "label": "UTC+01:00"},
    {"value": "Europe/Berlin", "label": "UTC+02:00"},
    {"value": "Europe/Moscow", "label": "UTC+03:00"},
    {"value": "Asia/Tehran", "label": "UTC+03:30"},
    {"value": "Asia/Dubai", "label": "UTC+04:00"},
    {"value": "Asia/Kabul", "label": "UTC+04:30"},
    {"value": "Asia/Karachi", "label": "UTC+05:00"},
    {"value": "Asia/Kolkata", "label": "UTC+05:30"},
    {"value": "Asia/Kathmandu", "label": "UTC+05:45"},
    {"value": "Asia/Dhaka", "label": "UTC+06:00"},
    {"value": "Asia/Yangon", "label": "UTC+06:30"},
    {"value": "Asia/Bangkok", "label": "UTC+07:00"},
    {"value": "Asia/Hong_Kong", "label": "UTC+08:00"},
    {"value": "Australia/Eucla", "label": "UTC+08:30"},
    {"value": "Australia/Adelaide", "label": "UTC+09:30"},
    {"value": "Asia/Tokyo", "label": "UTC+09:00"},
    {"value": "Australia/Sydney", "label": "UTC+10:00"},
    {"value": "Australia/Lord_Howe", "label": "UTC+10:30"},
    {"value": "Pacific/Noumea", "label": "UTC+11:00"},
    {"value": "Pacific/Auckland", "label": "UTC+12:00"},
    {"value": "Pacific/Chatham", "label": "UTC+12:45"},
    {"value": "Pacific/Tongatapu", "label": "UTC+13:00"},
    {"value": "Pacific/Kiritimati", "label": "UTC+14:00"} 
]

export default DataTimeZone;