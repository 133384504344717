import React from 'react';
import './custom.css';

import { useStateContext } from '../../../context/ContextProvider';
import DashAdmin from './DashAdmin';
import DashAdvertiser from './DashAdvertiser';
import DashClient from './DashClient';



function Dashboard() {

    const { user } = useStateContext()
    return (
        <div>
            {user?.role === "Admin" ?
                <DashAdmin />
                : user?.role === "Agency" ?
                    <DashAdvertiser /> 
                    :
                    <DashClient />}
        </div>


    )
}

export default Dashboard